<template>
  <down-full-up-popup
    :title="$t('Setting.TermThirdParty')"
    class="company-info-popup"
    @close="$emit('close')"
  >
    <div slot="body" v-html="text" />
  </down-full-up-popup>
</template>

<script>
import DownFullUpPopup from '../../../components/popups/DownFullUpPopup.vue';
import useShopInfo from '@/composables/shop/useShopInfo';

export default {
  props: ['name'],
  components: { DownFullUpPopup },
  setup(props) {
    const { info } = useShopInfo();

    const text = info.private3.replace('{{업체명}}', props.name);

    return {
      text,
    };
  },
};
</script>

<style>
.company-info-popup .pop_body * {
  font-size: 0.85rem;
  font-family: 'Noto Sans KR', 'sans-serif' !important;
}
</style>
