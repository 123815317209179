<template>
  <label class="chkbox font2 color37">
    <input
      v-model="checked"
      type="checkbox"
      :name="name"
      @click="$emit('click', $event)"
      @change="handleChangeCheck"
    />
    <slot />
    {{ item.label }}
  </label>
</template>

<script>
import { ref, watch } from 'vue-demi';
export default {
  props: ['item', 'value', 'name', 'passValueKey', 'isPassItem'],
  name: 'KsCheckBox',
  setup(props, context) {
    const checked = ref(props.value);

    watch(
      () => props.value,
      () => {
        checked.value = props.value;
      },
    );

    return {
      checked,
      handleChangeCheck() {
        let value = null;
        if (props.isPassItem) value = props.item;
        if (props.passValueKey) value = props.item[props.passValueKey];
        if (value === null) value = checked.value;
        console.log('handleChangeCheck');
        console.log(props.item);
        console.log(checked.value);
        context.emit('change', props.item, checked.value);
        context.emit('input', checked.value ? value : false, props.item);
      },
    };
  },
};
</script>

<style>
.chkbox {
  display: flex;
  align-items: center;
}
.chkbox input {
  margin-right: 10px;
}
</style>
