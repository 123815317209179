<template>
  <div class="wrap">
    <back-title-basket ref="basketCom" :title="$t('Order.Order')" />

    <div class="layout_body box_p60">
      <div class="inner order_wrap">
        <div>
          <h3 class="new_sub_title font4">{{ $t('Order.OrderInfo') }}</h3>
        </div>
        <product-order-item
          v-for="(item, idx) in selected"
          :key="item.cde_id"
          :cde_id="item.cde_id"
          :cit_id="item.cit_id"
          :isSelect="false"
          :isSetCopons:="isSetCopons"
          :isShippingAmount="isShippingAmount[idx]"
          :isShowClose="true"
          :isShowCoupon="true"
          :item="item"
          :qty="item.qty"
          @close="handleCloseProductItem"
          @load:detail="handleLoadProductItem"
        />
      </div>
      <!--할인 정보-->
      <div class="border_top order_discount">
        <div class="order_tit">
          <h6>{{ $t('Order.DiscountInfo') }}</h6>
          <span>
            <a
              class="font2 front_ico reg_coupon wrap_flex"
              @click="isShowCouponView = true"
              v-text="$t('Order.CouponRegister')"
            />
          </span>
        </div>
        <ul class="inner pb40 wrap_flex order_inform">
          <li>
            <label class="font2" for="coupons">
              {{ $t('Order.CouponNum')
              }}<span class="font2">{{ useCouponToTal + '개' }}</span>
            </label>
            <input
              id="coupons"
              :value="coupon_price.numberFormat()"
              class="btn_group"
              readonly
              type="text"
            />
            <button
              class="black_btn btn_pop_open"
              @click="isShowApplyDiscount = true"
              v-text="$t('Order.LookUp')"
            />
          </li>
          <li>
            <div v-if="pointCheck">
              <label for="point">
                {{
                  $t('Order.SILDPoint', { Point: point.numberFormat() })
                }}</label
              >
              <input-number
                v-if="pointCheck"
                :key="maxPoint"
                v-model="usePoint"
                :max="maxPoint"
                :readonly="total_price < 30000 && payPrice < 30000"
                @change="checkPoinAndCouponPay"
                @focusIn="handelPointInputFocus"
                @focusout="loadDelivery()"
              />

              <button
                :disabled="usePoint === maxPoint"
                class="black_btn"
                @click="handlePoint"
              >
                {{ $t('Order.AllUse') }}
              </button>
            </div>
            <div v-else>
              <input-number
                :key="maxPoint"
                v-model="usePoint"
                :max="maxPoint"
                readonly
                @change="checkPoinAndCouponPay"
                @focusout="loadDelivery()"
              />
              <button class="black_btn" disabled @click="handlePoint">
                {{ $t('Order.AllUse') }}
              </button>
            </div>
            <p class="desc-point">
              {{ $t('Order.AlertPoint') }}
            </p>
          </li>
        </ul>
      </div>
      <!--배송지 정보-->
      <div class="border_top order_discount">
        <div class="order_tit">
          <h6>{{ $t('Delivery.DeliveryInfo') }}</h6>
          <a
            class="font2 front_ico map wrap_flex"
            @click="isShowDelivery = true"
            v-text="$t('Mypage.AddressList')"
          />
        </div>
        <ul class="inner pb40 wrap_flex order_inform">
          <li>
            <label for="userName">{{ $t('Delivery.Recipient') }}</label>
            <input
              id="userName"
              v-model="delivery.recipient_name"
              class="btn_group width100"
              maxlength="20"
              type="text"
            />
          </li>
          <li>
            <label class="font4" for="phoneNum">{{
              $t('Delivery.PhoneNumber')
            }}</label>
            <div class="phone_input disflex">
              <select-box
                v-model="delivery.phone[0]"
                :items="phoneNumbers"
                class="width33"
              />
              <input-number
                v-model="delivery.phone[1]"
                :maxLength="4"
                class="btn_group width33"
              />
              <input-number
                v-model="delivery.phone[2]"
                :maxLength="4"
                class="btn_group width33"
              />
            </div>
          </li>
          <li>
            <label class="font4" for="address">{{
              $t('Delivery.ZipCode')
            }}</label>
            <input
              id="address"
              v-model="delivery.recipient_zip"
              class="btn_group back_color7"
              readonly
              type="text"
            />
            <button class="black_btn" @click="handleClickJuso">
              {{ $t('Delivery.ZipCode') }}
            </button>
          </li>
          <li>
            <label for="add02">{{ $t('Delivery.EditAddress') }}</label>
            <input
              id="add02"
              v-model="delivery.recipient_addr1"
              class="btn_group width100 back_color7"
              readonly
              type="text"
            />
          </li>
          <li>
            <label for="add03">{{ $t('Delivery.DetailAddress') }}</label>
            <input
              id="add03"
              v-model="delivery.recipient_addr2"
              :placeholder="$t('Delivery.AlertDetail')"
              class="btn_group width100"
              maxlength="50"
              type="text"
            />
          </li>
          <li>
            <label for="userName">{{ $t('OrderInquiry.ShippingMemo') }}</label>
            <select-box
              v-model="memoType"
              :items="memos"
              :placeholder="$t('Order.AlertMemo')"
              class="type06 type02"
              @change="handleChangeMemoType"
            />

            <div v-if="memoType === 3">
              <label for="userName"></label>
              <input
                id="userName"
                v-model="memo2"
                :placeholder="$t('Order.Max28')"
                class="width100"
                maxlength="28"
                type="text"
              />
            </div>
          </li>
        </ul>
      </div>
      <!--결제금액-->
      <div class="border_top order_discount">
        <div class="order_tit">
          <h6>{{ $t('Basket.PaymentAmount') }}</h6>
        </div>
        <div class="inner p20 order_discount">
          <div class="width100">
            <ul class="disflex cart_list pay_list">
              <li>
                <span> {{ $t('Order.AllProductAmount') }}</span>
                <span> ₩ {{ payPrice.numberFormat() }} </span>
              </li>
              <li>
                <span> {{ $t('Order.AllShippingAmount') }}</span>
                <span>₩ {{ total_delivery.numberFormat() }}</span>
              </li>

              <template v-if="total_delivery > 0">
                <li v-for="item in deliveries" :key="item.den_id" class="brand">
                  <span>-{{ item.den_name }}</span>
                  <span>₩ {{ item.price.numberFormat() }}</span>
                </li>
              </template>
              <li>
                <span>{{ $t('Basket.DiscountAmount') }}</span
                ><span>
                  {{ $t('Basket.MonetaryUnit') }}
                  {{ discount_price.numberFormat() }}</span
                >
              </li>
              <li>
                <span> {{ $t('Order.UseCoupon') }}</span>
                <span> ₩ {{ coupon_price.numberFormat() }} </span>
              </li>
              <li>
                <span>{{ $t('Basket.RankingSalePrice') }}</span>
                <span>₩ {{ ranking_price.numberFormat() }}</span>
              </li>
              <li>
                <span>{{ $t('Order.UsePoint') }}</span>
                <span>₩ {{ usePoint.numberFormat() }}</span>
              </li>
              <li class="all_payment">
                <span class="font6">{{ $t('Order.AllPaymentAmount') }}</span>
                <strong class="font8 fontNum">
                  ₩
                  {{ Number(total_price).numberFormat() }}
                </strong>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--결제방식-->
      <div class="border_top order_discount">
        <div class="order_tit">
          <h6>{{ $t('OrderInquiry.PaymentType') }}</h6>
        </div>
        <div class="inner pb30 address_info">
          <div class="width100">
            <ul class="disflex pay_type">
              <li>
                <input
                  id="type1"
                  :checked="info.payment_agr === 'kakao' ? true : false"
                  data-type="kakao"
                  name="order_type"
                  type="radio"
                  value="CARD"
                  @change="handleChangeType"
                />
                <label class="color37 font2" for="type1">{{
                  $t('OrderInquiry.KakaoPay')
                }}</label>
              </li>
              <li>
                <input
                  id="type6"
                  :checked="info.payment_agr === 'naver' ? true : false"
                  data-type="naver"
                  name="order_type"
                  type="radio"
                  value="CARD"
                  @change="handleChangeType"
                />
                <label class="color37 font2" for="type6">{{
                  $t('OrderInquiry.NaverPay')
                }}</label>
              </li>
              <li>
                <input
                  id="type2"
                  :checked="info.payment_agr === 'card' ? true : false"
                  data-type="card"
                  name="order_type"
                  type="radio"
                  value="CARD"
                  @change="handleChangeType"
                />
                <label class="color37 font2" for="type2">{{
                  $t('CancelList.CreditCard')
                }}</label>
              </li>
              <li>
                <input
                  id="type3"
                  :checked="info.payment_agr === 'VBANK' ? true : false"
                  name="order_type"
                  type="radio"
                  value="VBANK"
                  @change="handleChangeType"
                />
                <label class="color37 font2" for="type3">{{
                  $t('OrderInquiry.VirtualAccount')
                }}</label>
              </li>
              <!--              <li>-->
              <!--                <input-->
              <!--                  id="type4"-->
              <!--                  name="order_type"-->
              <!--                  type="radio"-->
              <!--                  value="BANK"-->
              <!--                  @change="handleChangeType"-->
              <!--                />-->
              <!--                <label class="color37 font2" for="type4">{{-->
              <!--                  $t('OrderInquiry.BankTransfer')-->
              <!--                }}</label>-->
              <!--              </li>-->
              <li class="disflex chk_order all_payment width100">
                <input
                  id="payment_agr"
                  :checked="payType === card_type ? true : false"
                  :value="card_type"
                  type="checkbox"
                  @change="handleTypeSave"
                /><label class="chkbox font2" for="payment_agr">
                  {{ $t('Order.AlertType') }}
                </label>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--결제금액-->
      <div class="border_top order_discount">
        <div class="inner p20 order_discount">
          <div class="width100">
            <ul class="disflex cart_list">
              <li>
                <span class="font6">{{ $t('Order.BuyPoint') }}</span
                ><strong class="font8">{{
                  Math.floor(total_price * 0.01).numberFormat() + 'P'
                }}</strong>
              </li>
              <li class="pb20">
                <span class="font6">{{ $t('Order.FirstReview') }}</span
                ><strong class="font8">{{ $t('Order.Max5000P') }}</strong>
              </li>
              <li class="mb20">
                <span class="font2">{{ $t('Order.AlertUse') }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--결제금액-->
      <div class="border_top order_agreement">
        <div>
          <h6 class="allPage_tit">{{ $t('Order.TermAgree') }}</h6>
          <ul class="useragree wrap_flex">
            <li>
              <input
                id="allChk"
                :checked="agree[0] === true && agree[1] === true"
                type="checkbox"
                @change="handleClickAgreeAll"
              />
              <label class="chkbox font4 fontW500" for="allChk">
                {{ $t('Order.AllAgree') }}
              </label>
            </li>
            <li>
              <input
                id="necessary"
                v-model="agree[0]"
                class="normal"
                type="checkbox"
              />
              <label class="chkbox cheked" for="necessary">
                {{ $t('Order.InfoAgree') }}
              </label>
            </li>
            <li class="font2 colora8" v-html="$t('Order.PurchaseAgree')"></li>
            <li class="disflex pb30">
              <input
                id="selecEct"
                v-model="agree[1]"
                class="normal"
                type="checkbox"
              />
              <label class="chkbox cheked" for="selecEct">
                {{ $t('Order.ThirdParty') }}
              </label>
              <button
                class="white_btn flex_right btn_pop_open"
                type="button"
                @click="isShowConsent = true"
              >
                {{ $t('Join.ContentOpen') }}
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div id="error_frame"></div>
      <div id="error_report"></div>
      <button class="black_btn font4" type="button" @click="handleClickBuy">
        결제하기
      </button>
    </div>
    <pay-form
      v-if="isShowPayForm"
      :card_type="card_type"
      :coupon_price="coupon_price"
      :coupons="selecteCoupons"
      :delivery="delivery"
      :easyPayAttrs="easyPayAttrs"
      :isShippingAmount="isShippingAmount"
      :items="items"
      :method="order_type"
      :payType="usePayment"
      :product_price="payPrice"
      :total_delivery="total_delivery"
      :total_price="total_price"
      :usePoint="usePoint"
      :usegrade="ranking_price"
      @close="isShowPayForm = false"
    />

    <sild-footer />
    <down-full-up-popup
      v-if="isShowCouponView"
      :title="$t('Mypage.Coupon')"
      class="order-coupon-view"
      @close="isShowCouponView = false"
    >
      <coupon-view slot="body" />
    </down-full-up-popup>
    <apply-discount
      v-if="isShowApplyDiscount"
      :coupons="coupons"
      :providers="providers"
      :selecteCoupon="selecteCoupons"
      :selectedItems="selectedItem"
      :totalPrice="coupons"
      @close="isShowApplyDiscount = false"
      @confirm="handleConfirmCoupons"
    />
    <deliverly-popup
      v-if="isShowDelivery"
      @close="isShowDelivery = false"
      @set:default="handleClickSetDefault"
    />
    <third-party-consent-popup
      v-if="isShowConsent"
      :name="name"
      @close="isShowConsent = false"
    />
    <personal-info v-if="isShowPrivate" @close="isShowPrivate = false" />
    <down-up-popup v-if="isAddress" title="주소검색" @close="handleAddreeClose">
      <div slot="body">
        <AddressDaumPOp @addressResult="handleGetAddress" />
      </div>
    </down-up-popup>
  </div>
</template>

<script>
import { reactive, ref, toRefs, watch } from 'vue-demi';
import SildFooter from '../../components/layouts/components/footers/SildFooter.vue';
import ProductOrderItem from '../../components/ui/ProductOrderItem/ProductOrderItem.vue';
import PayForm from './PayForm.vue';
import DownFullUpPopup from '../../components/popups/DownFullUpPopup.vue';
import CouponView from '../mypages/coupons/CouponView.vue';
import ApplyDiscount from '../../components/popups/ApplyDiscount.vue';
import SelectBox from '../../components/Inputs/SelectBox/SelectBox.vue';
import InputNumber from '../../components/Inputs/InputNumber/InputNumber.vue';
import useUserInfo from '../../composables/user/useUserInfo';
import DeliverlyPopup from './popups/DeliverlyPopup.vue';
import DeliveryAPI from '../../apis/DeliveryAPI';
import BackTitleBasket from '@/components/layouts/components/headers/BackTitleBasket.vue';
import ThirdPartyConsentPopup from '../settings/popups/ThirdPartyConsentPopup.vue';
import PersonalInfo from '../../views/auth/popups/PersonalInfo.vue';
import useAlert from '@Composables/dialogs/useAlert';
import PaymentAPI from '@/apis/PaymentAPI';
import DownUpPopup from '@Popups/DownUpPopup.vue';
import AddressDaumPOp from '@Components/AddressDaumPop.vue';
import useCouponToTal from '@/apis/useCouponToTal';
// import PrivatePopup from '../settings/popups/PrivatePopup.vue';

export default {
  components: {
    AddressDaumPOp,
    DownUpPopup,
    SildFooter,
    ProductOrderItem,
    PayForm,
    DownFullUpPopup,
    CouponView,
    ApplyDiscount,
    SelectBox,
    InputNumber,
    DeliverlyPopup,
    BackTitleBasket,
    ThirdPartyConsentPopup,
    PersonalInfo,
  },
  setup(_, context) {
    const { info } = useUserInfo();
    const selected = JSON.parse(localStorage.getItem('selected'));
    const coupons = JSON.parse(localStorage.getItem('coupons'));
    const couponPrice = JSON.parse(localStorage.getItem('couponPrice'));
    const providers = JSON.parse(localStorage.getItem('providers'));
    const selectedItemData = JSON.parse(localStorage.getItem('selected'));
    const selectedItemSet = JSON.parse(localStorage.getItem('selectedItem'));
    const { showAlert } = useAlert();
    const addressPop = ref(null);
    const basketCom = ref(null);
    const state = reactive({
      payType: '',
      alertText: String,
      pointCheck: false,
      selected,
      items: [],
      usePoint: 0,
      maxPoint: info.mem_point,
      total_price: 0,
      total_delivery: 0,
      // coupons: coupons !== 0 ? coupons : ,
      coupons: coupons ? coupons : [],
      coupon_price: couponPrice ? couponPrice : 0,
      companies: [],
      deliveries: [],
      providers: providers,
      order_type: info.payment_agr === 'VBANK' ? 'VBANK' : 'CARD',
      isShowJuso: false,
      isShowCouponView: false,
      isShowApplyDiscount: false,
      isShowDelivery: false,
      isShowConsent: false,
      isShowPrivate: false,
      selectedItem: selectedItemData,
      selecteCoupons: coupons ? coupons : [],
      totalPrice: couponPrice,
      ranking_price: 0,
      delivery: {
        recipient_name: info.mem_username,
        recipient_phone: info.mem_phone,
        recipient_zip: '',
        recipient_addr1: '',
        recipient_addr2: '',
        recipient_memo: '',
        phone: [],
      },
      usePayment: {},
      easyPayAttrs: {
        DirectShowOpt: 'CARD',
        NicepayReserved: 'DirectKakao=Y',
      },
      card_type: info.payment_agr === 'VBANK' ? '' : info.payment_agr,
      agree: new Array(2).fill(false),
      selectDenId: null,
      isShowPayForm: false,
      memoType: null,
      memo1: '',
      memo2: '',
      name: [],
      isSetCopons: '',
      payPrice: 0,
      discount_price: 0,
      isAddress: false,
      isGetBrandIds: [],
      useCouponToTal: 0,
      isGetDentId: null,
      isShippingAmount: [],
    });
    watch(
      () => state.isShowApplyDiscount,
      (newValue) => {
        if (newValue) {
          state.usePoint = 0;
        }
      }
    );

    switch (state.card_type) {
      case 'kakao':
        {
          state.easyPayAttrs = {
            DirectShowOpt: 'CARD',
            DirectKakao: 'Y',
            NicepayReserved: 'DirectKakao=Y',
          };
        }
        break;
      case 'naver':
        {
          state.easyPayAttrs = {
            DirectShowOpt: 'CARD',
            DirectEasyPay: 'E020',
            EasyPayMethod: 'E020=CARD',
          };
        }
        break;
      default:
        state.easyPayAttrs = {};
        break;
    }
    const loadDelivery = async (newZoneCode, newAddr) => {
      await DeliveryAPI.getMyDefaultDelivery({
        mem_id: localStorage.getItem('mem_id'),
      }).then((result_data) => {
        setDelivery(result_data.data.result_data);
        loadData(newZoneCode, newAddr);
      });
    };
    const loadData = async (newZoneCode, newAddr) => {
      newAddr
        ? (state.delivery.recipient_addr1 = newAddr)
        : state.delivery.recipient_addr1;
      newZoneCode
        ? (state.delivery.recipient_zip = newZoneCode)
        : state.delivery.recipient_zip;
      let basketItem = JSON.parse(localStorage.getItem('basket'));
      let isSelecteData = [];
      let isCoupons = [];
      state.isShippingAmount = [];
      state.isGetBrandIds = [];
      //업체명 삽입
      const keys = Object.values(state.providers || {});

      let selectArr = [];

      selectedItemSet.filter((selectedItem) => {
        console.log(selectedItem);

        console.log(selectedItem.provider);
        if (selectedItem.den_id) {
          selectArr.push(
            state.providers[selectedItem.den_id].provider.den_name
          );
        } else if (!selectedItem.den_id) {
          keys.map((item) => {
            selectArr.push(item.provider.den_name);
          });
        } else {
          selectArr.push(
            state.providers[selectedItem.provider.den_id].provider.den_name
          );
        }
      });
      const newSelectArr = [...new Set(selectArr)];
      state.name = newSelectArr;

      if (
        Array.isArray(basketItem) ? basketItem.length > 0 : basketItem !== null
      ) {
        console.log(
          '들어옴들어옴들어옴들어옴들어옴들어옴들어옴들어옴들어옴들어옴들어옴들어옴들어옴'
        );
        basketItem = basketItem.map((basketItem) => basketItem.den_id);
        basketItem = basketItem.filter((value, index, self) => {
          return self.indexOf(value) === index;
        });
        await useCouponToTal
          .getUseCouponGetCount({
            mem_id: localStorage.getItem('mem_id'),
            den_ids: basketItem.length > 1 ? basketItem.join(',') : basketItem,
          })
          .then((res) => {
            state.useCouponToTal = res.data.result_data;
          });
      } else {
        watch(
          () => state.isGetDentId,
          async (newValue) => {
            state.isGetDentId = newValue;
            await useCouponToTal
              .getUseCouponGetCount({
                mem_id: localStorage.getItem('mem_id'),
                den_ids: newValue,
              })
              .then((res) => {
                state.useCouponToTal = res.data.result_data;
              });
          }
        );
      }

      state.selectedItem.map((item) => {
        isSelecteData.push({
          cit_id: item.cit_id,
          cde_id: item.cde_id,
          qty: item.qty,
        });
      });

      state.selecteCoupons.map((couponsItem) => {
        isCoupons.push({
          ccl_id: couponsItem.ccl_id,
        });
      });

      // console.log('-> isSelecteData', isSelecteData);
      // console.log('-> state.coupons', state.coupons);
      // console.log('-> stat.selecteCoupons', state.selecteCoupons);
      // console.log('-> state.usePoint', state.usePoint);
      // if (isCoupons.length > 0) {
      //   state.usePoint = 0;
      // }
      console.log('@@@@@@@@@@@@@@@@@');
      console.log(
        'state.delivery.recipient_addr1',
        state.delivery.recipient_addr1
      );
      console.log('state.delivery.recipient_zip', state.delivery.recipient_zip);
      console.log('@@@@@@@@@@@@@@@@@');
      const { data } = await PaymentAPI.calculateData({
        items: JSON.stringify(isSelecteData),
        mem_id: localStorage.getItem('mem_id')
          ? localStorage.getItem('mem_id')
          : null,
        coupons: JSON.stringify(isCoupons),
        do_addr: state.delivery.recipient_addr1,
        do_zip: state.delivery.recipient_zip,
        use_point: state.usePoint,
      });

      // const { data } = await useCouponToTal.getUseCouponGetCount({
      //   mem_id: localStorage.getItem('mem_id'),
      // })

      keys.map((provider) => {
        state.isGetBrandIds.push(provider.provider.den_id);
      });
      console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$");
      console.log(data);
      console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$");

      data.goodsList.map((list_item) => {
        state.isShippingAmount.push(list_item.deliveryPrice);
      });
      console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
      console.log(state.isShippingAmount);
      console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
      // 모든것들을 이 api 를 통해 작업 하도록

      // 총 주문 금액
      state.payPrice = data.sumGoodsPrice;
      // 쿠폰 금액
      state.coupons = data.sumCouponUse;

      // 총 배송비
      state.total_delivery = data.sumDeliveryPrice;
      // 등급 할인 금액
      state.ranking_price = data.sumGradeSale;
      state.usePoint = data.sumPointUse;
      // 할인금액
      state.discount_price = data.sumDiscount;
      // 결제해야할 금액
      state.total_price = data.settlePrice;

      await setCoupons(state.coupons);
    };

    loadDelivery();

    if (info && info.mem_phone) {
      const phone = info.mem_phone.replace(/[-]/g, '');

      state.delivery.phone = [
        phone.substr(0, 3),
        phone.substr(3, 4),
        phone.substr(7, 4),
      ];
    }

    if (!selected?.length) {
      context.root.$router.go(-1);
    }
    const setCheckInfo = () => {
      console.log(info.payment_agr);
      if (info.payment_agr) {
        state.card_type = info.payment_agr;
        state.payType = state.card_type;
        console.log(state.payType);
      }
    };
    setCheckInfo();
    const setDelivery = (delivery) => {
      const phone = delivery.recipient_phone;

      state.delivery.phone = [
        phone.substr(0, 3),
        phone.substr(3, 4),
        phone.substr(7, 4),
      ];

      state.delivery.recipient_name = delivery.recipient_name;
      state.delivery.recipient_phone = delivery.recipient_phone;
      state.delivery.recipient_addr1 = delivery.road_addr;
      state.delivery.recipient_addr2 = delivery.detail_addr;
      state.delivery.recipient_zip = delivery.zipcode;
    };

    const memos = [
      context.root.$t('Order.GuardRoom'),
      context.root.$t('Order.Message'),
      context.root.$t('Order.Contact'),
      context.root.$t('Order.EnterMemo'),
    ].map((val, idx) => ({ key: idx, label: val }));
    const updatePoint = () => {
      const payValue = state.total_price;
      state.maxPoint = Math.min(payValue, Number(info.mem_point));
      console.log('-> patValue', payValue);
      console.log('-> state.maxPoint', state.maxPoint);
    };
    const handlePoint = () => {
      console.log('point :: ', state.usePoint);
      state.total_price =
        parseInt(state.total_price) + parseInt(state.usePoint);
      console.log('total_price :: ', state.total_price);

      updatePoint();
      state.usePoint = state.maxPoint;
      loadData();
    };
    const setCoupons = (coupons) => {
      state.coupon_price = coupons;
      state.isSetCopons = coupons;
      let totalDiscount =
        state.coupon_price + state.usePoint + state.ranking_price;
      if (state.payPrice < 30000 && totalDiscount > state.payPrice) {
        showAlert('주문 결제 금액보다 할인 금액이 클 수 없습니다.');
        state.usePoint = 0;
        loadData();
      }
      // if (state.usePoint % 1000 !== 0) {
      //   showAlert(context.root.$t("Order.AlertAmount"));
      //   state.usePoint = 0;
      //   state.maxPoint = 0;
      //   loadData();
      // }
      updatePoint();
    };

    if (info.mem_point !== '0') {
      state.pointCheck = true;
    } else {
      state.pointCheck = false;
    }

    return {
      info,
      addressPop,
      basketCom,
      ...toRefs(state),
      loadDelivery,
      point: info.mem_point,
      memos,
      phoneNumbers,
      handlePoint,
      handleLoadProductItem(item) {
        state.isGetDentId = item.product.den_id;
        state.items.push(item);
      },
      async handleClickBuy() {
        console.log(state.card_type);
        console.log('-> state.total_price', state.total_price);
        console.log('결제하기');
        let isValidaTions = false;

        if (state.agree[0] !== true || state.agree[1] !== true) {
          return showAlert(context.root.$t('Order.AlertTermAgree'));
        }

        if (state.delivery.phone.length < 2) {
          return showAlert(context.root.$t('Order.PhoneNumberAlert'));
        }
        if (state.delivery.recipient_name === '') {
          return showAlert(context.root.$t('Order.recipientName'));
        }

        if (
          state.delivery.recipient_addr1 === '' ||
          state.delivery.recipient_addr1 === null
        ) {
          return showAlert(context.root.$t('Delivery.AlertAddress'));
        }

        if (
          state.delivery.recipient_addr2 === '' ||
          state.delivery.recipient_addr2 === null
        ) {
          return showAlert(context.root.$t('Delivery.AlertDetail'));
        }
        isValidaTions = true;

        console.log('isValidaTions', isValidaTions);
        if (isValidaTions) {
          state.delivery.recipient_memo =
            state.memoType < 3 ? state.memo1 : state.memo2;
          state.isShowPayForm = true;
        }
      },
      handleCloseAlertPopup(isPopup) {
        state.isAlert = isPopup;
      },
      handleChangeType(e) {
        if (e.target.checked) {
          state.card_type = e.target.getAttribute('data-type');
          state.order_type = e.target.value;

          switch (state.card_type) {
            case 'kakao':
              {
                state.easyPayAttrs = {
                  DirectShowOpt: 'CARD',
                  DirectKakao: 'Y',
                  NicepayReserved: 'DirectKakao=Y',
                };
              }
              break;
            case 'naver':
              {
                state.easyPayAttrs = {
                  DirectShowOpt: 'CARD',
                  DirectEasyPay: 'E020',
                  EasyPayMethod: 'E020=CARD',
                };
              }
              break;
            default:
              state.easyPayAttrs = {};
              break;
          }
        }
      },
      handleTypeSave(e) {
        if (e.target.checked) {
          state.payType = state.card_type;
          if (state.payType === null) {
            state.payType = state.order_type;
          }
        }
        state.usePayment = {
          payment_agr: state.payType,
          payment_way: e.target.checked ? 'y' : 'n',
        };

        console.log('!!!!!!! 사용 페이타입');
        console.log(state.usePayment);
        console.log(state.payType);
        console.log(state.order_type);
        console.log('!!!!!!! 사용 페이타입');
      },
      handleCloseProductItem(cde_id) {
        if (state.selected.length > 1) {
          state.items = state.items.filter(
            (sItem) => sItem.detail.cde_id !== cde_id
          );
          state.selected = state.selected.filter(
            (sItem) => sItem.cde_id !== cde_id
          );
          const selectedItemIndexes = [];
          state.selectedItem.forEach((item, idx) => {
            if (item.cde_id === cde_id) {
              selectedItemIndexes.push(idx);
            }
          });
          selectedItemIndexes.reverse().forEach((idx) => {
            state.selectedItem.splice(idx, 1);
          });
          let basketItem = JSON.parse(localStorage.getItem('basket'));
          let selectedItem = JSON.parse(localStorage.getItem('selected'));
          let newSelectedItem = [];
          basketItem = basketItem.filter((basket) => basket.cde_id !== cde_id);
          localStorage.removeItem('basket');
          localStorage.setItem('basket', JSON.stringify(basketItem));
          basketCom.value.$children[1].handleSetCount();
          selectedItem.forEach((obj1) => {
            state.selectedItem.forEach((obj2) => {
              if (obj1.cit_id === obj2.cit_id) {
                newSelectedItem.push(obj1);
              }
            });
          });
          localStorage.removeItem('selected');
          localStorage.setItem('selected', JSON.stringify(newSelectedItem));
          loadDelivery();
        }
      },
      handleAddreeClose() {
        state.isAddress = false;
      },
      handleConfirmCoupons(couponPrice, coupon) {
        state.selecteCoupons = [];
        state.coupons = coupon;
        state.selecteCoupons = coupon.reduce(function (acc, v) {
          if (acc.findIndex(({ ccl_id }) => ccl_id === v.ccl_id) === -1) {
            acc.push(v);
          }
          return acc;
        }, []);
        loadDelivery();
        state.isShowApplyDiscount = false;
      },
      handleClickJuso() {
        state.isAddress = true;
      },
      handleGetAddress(addressResult) {
        state.delivery.recipient_zip = addressResult.zonecode;
        state.delivery.recipient_addr1 = addressResult.roadAddress
          ? addressResult.roadAddress
          : addressResult.address;
        state.isAddress = false;
        watch(
          () => state.delivery.recipient_addr1,
          (newValue) => {
            state.delivery.recipient_addr1 = newValue;
          }
        );
        console.log(state.delivery.recipient_addr1);
        this.loadDelivery(
          state.delivery.recipient_zip,
          state.delivery.recipient_addr1
        );
      },
      oncomplete() {},
      handleClickSetDefault(delivery) {
        setDelivery(delivery);
        state.isShowDelivery = false;
      },
      handleClickAgreeAll(e) {
        state.agree = new Array(2).fill(e.target.checked);
      },

      handleChangeMemoType(_, item) {
        state.memo1 = item.key === 3 ? '' : item.label;
      },

      checkPoinAndCouponPay() {
        console.log('-> state.coupon_price', state.coupon_price);
        console.log('-> state.usePoint', state.usePoint);
      },
      handelPointInputFocus() {
        state.usePoint = 0;
        loadData();
      },
    };
  },
};

const phoneNumbers = ['010', '011', '017', '019', '070'].map((val) => ({
  key: val,
  label: val,
}));
</script>

<style>
.order-coupon-view .layout_body {
  display: block;
  padding-bottom: 0;
  padding-top: 0;
}
.desc-point {
  color: #a8a8a8;
  font-size: 12px;
  margin-top: 10px;
  text-align: right;
}
</style>
