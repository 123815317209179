<template>
  <down-up-popup
    :title="$t('Product.Quantity')"
    class="fix_foot_btn option_change_popup"
    @close="$emit('close')"
  >
    <div slot="body" class="option_box">
      <div v-if="product" class="review_con disflex">
        <product-image :item="product" class="review_pro" />
        <div class="detail_pro">
          <h6 class="fontEn font2">{{ product.cit_name }}</h6>
          <p class="font2">
            {{ product.cit_summary }}
          </p>
          <p class="font2">{{ details.cde_title }}</p>
        </div>
      </div>
      <div>
        <div>
          <button
            class="pro_add"
            type="button"
            @click="handleClickSub"
          ></button>
          {{ num }}
          <button
            class="pro_redu"
            type="button"
            @click="handleClickAdd"
          ></button>
        </div>
      </div>
      <ul class="sec_floor">
        <li>
          <P> {{ $t('Order.AllProductAmount') }} </P>
          <h6 class="fontNum">
            ₩
            {{ price.numberFormat() }}
          </h6>
        </li>
      </ul>
    </div>
    <div slot="footer" class="pop_foot">
      <button type="button" @click="$emit('close')">
        {{ $t('Popup.Cancel') }}
      </button>
      <button type="button" @click="handleChangeOption">
        {{ $t('Popup.Change') }}
      </button>
    </div>
  </down-up-popup>
</template>

<script>
import DownUpPopup from '@Popups/DownUpPopup.vue';
import ProductAPI from '@/apis/ProductAPI';
import { reactive, ref, toRefs } from 'vue-demi';
import ProductImage from '@UI/ProductImage/ProductImage.vue';

export default {
  name: 'ProductChangeQty',
  components: { DownUpPopup, ProductImage },
  props: ['product', 'detail'],
  setup(props, context) {
    const cit_price = ref(props.detail.cit_price);
    const discount_price = ref(props.detail.discount_price);
    const state = reactive({
      is_options: false,
      selected: [],
      details: props.detail,
      optionKey: Date.now(),
      price: null,
    });
    const loadOptions = async () => {
      const { data } = await ProductAPI.getProductOptions(props.product.cit_id);
      state.is_options = data.options.length > 0;
    };
    console.log('-> state.details', state.details);

    console.log('-> props.product', props.product);
    const min = props.min ?? 1;
    const num = ref(state.details.qty);
    state.price = ref(
      props.product.is_sale === 'y'
        ? (props.detail.cit_price - props.detail.discount_price) *
            props.detail.qty
        : props.detail.cit_price * props.detail.qty,
    );
    loadOptions();
    return {
      ...toRefs(state),
      props,
      num,
      handleSelectOption(item) {
        state.selected = [item];
        context.emit('input', state.selected);
      },
      handleDeleteItem(item) {
        const selected = state.selected.filter(value => value !== item);
        state.selected = selected;
        context.emit('input', selected);
      },
      handleClickAdd() {
        if (props.disabled) return;

        if (props.max && props.max <= num.value) return;

        num.value += 1;
        if (props.product.is_sale === 'y') {
          state.price = (cit_price.value - discount_price.value) * num.value;
        } else {
          state.price = cit_price.value * num.value;
        }
      },
      handleClickSub() {
        if (props.disabled) return;
        if (num.value <= min) return;

        num.value -= 1;

        if (props.product.is_sale === 'y') {
          state.price = (cit_price.value - discount_price.value) * num.value;
        } else {
          state.price = cit_price.value * num.value;
        }
      },
      handleChangeOption() {
        let items = localStorage.getItem('basket');
        items = JSON.parse(items);
        items.filter(item => {
          if (item.cde_id === props.detail.cde_id) {
            item.qty = num.value;
          }
          return item;
        });
        localStorage.setItem('basket', JSON.stringify(items));
        context.emit('change');
      },
    };
  },
};
</script>

<style scoped></style>
