<template>
  <div class="search_brand_con brand-coupon-header">
    <div class="disflex">
      <div v-if="item" class="con pro_desc_box type3">
        <image-bg-src :src="item.seller_picture" class="profile_img" />
        <div class="profile_user">
          <!-- <h3 class="name fontWb">{{ item.den_en_name }}</h3> -->
          <h3 class="name fontWb">{{ item.den_name }}</h3>
          <p class="desc">{{ item.den_name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageBgSrc from '../ImageBlock/ImageBgSrc.vue';
export default {
  components: { ImageBgSrc },
  props: ['item'],
};
</script>

<style>
.brand-coupon-header.search_brand_con {
  border-top: none;
  padding-top: 0;
}
.brand-coupon-header.search_brand_con > div > div {
  height: 40px;
  padding: 10px 0;
  display: flex;
  align-items: center;
}
</style>
