<template>
  <div v-if="product" class="search_brand_con cart_con">
    <div class="disflex">
      <div v-if="provider" class="con pro_desc_box type3">
        <!-- <router-link to="/"> -->
        <image-bg-src
          :src="provider.img_url ? provider.img_url : provider.seller_picture"
          class="profile_img"
          @click="redirectToBrand"
        />
        <div class="profile_user">
          <h3 class="name fontWb">{{ provider.den_name }}</h3>
          <p class="desc">{{ provider.provider_summary }}</p>
        </div>
        <!-- </router-link> -->
      </div>
      <p v-if="isShowCoupon" class="con">
        <a
          class="radi_btnG btn_pop_open"
          data-popup="coupon_download_pop"
          @click="handleGetCupone"
        >
          {{ $t("Popup.CouponDown") }}
        </a>
      </p>
    </div>
    <div class="disflex border_none" style="padding: 3px 0">
      <label class="chkbox ell_w27 font2 color37">
        <input
          v-if="isSelect"
          :checked="value"
          type="checkbox"
          @change="handleSelected"
        />
        {{ product.cit_summary }}
      </label>
      <button
        v-if="isShowClose"
        class="close flex_right immediately_del"
        type="button"
        @click="$emit('close', cde_id)"
      />
    </div>
    <!-- <router-link to="/" v-if="isLink"> -->
    <product-info
      v-if="product && detail"
      :deliveryValue="isShippingAmount"
      :detail="detail"
      :product="product"
    />
    <!-- </router-link> -->

    <!-- <product-info
      v-else-if="product && detail"
      :product="product"
      :detail="detail"
    /> -->

    <div v-if="isShowButton" class="cart_evnet">
      <button
        v-show="false"
        class="white_btn btn_pop_open"
        data-popup="option_pop"
        @click="handleClickChangeOption"
      >
        {{ $t("Popup.OptionChange") }}
      </button>
      <button
        class="white_btn btn_pop_open"
        data-popup="option_pop"
        @click="isShowChangeQty = true"
      >
        수량변경
      </button>
      <button class="black_btn" @click="handleClickOrder">
        {{ $t("Product.Order") }}
      </button>
    </div>

    <brand-coupons
      v-if="isShowBrandCoupons"
      :den_id="product.den_id"
      @close="isShowBrandCoupons = false"
    />
    <ProductChangeQty
      v-if="isShowChangeQty && product"
      :detail="detail"
      :product="product"
      @change="handelQtyChange"
      @close="isShowChangeQty = false"
    />
    <product-option-change-popup
      v-if="isShowChangeOption && product"
      :product="product"
      @change="handleChangeOption"
      @close="isShowChangeOption = false"
    />
  </div>
</template>

<script>
import { reactive, toRefs, watch } from "vue-demi";
import ProductAPI from "../../../apis/ProductAPI";
import ImageBgSrc from "../ImageBlock/ImageBgSrc.vue";
import ProductInfo from "./ProductInfo.vue";
import BrandCoupons from "../../popups/BrandCoupons.vue";
import ProductOptionChangePopup from "../../popups/ProductOptionChangePopup.vue";
import ProductChangeQty from "@Popups/ProductChangeQty.vue";
import useAlert from "@Composables/dialogs/useAlert";

export default {
  components: {
    ProductChangeQty,
    ImageBgSrc,
    ProductInfo,
    BrandCoupons,
    ProductOptionChangePopup,
  },
  props: [
    "value",
    "cit_id",
    "cde_id",
    "qty",
    "isSelect",
    "isShowClose",
    "isShowCoupon",
    "isShowButton",
    "item",
    "isLink",
    "idx",
    "selecteCoupons",
    "coupon_price",
    "isShippingAmount",
  ],
  setup(props, context) {
    const { showAlert } = useAlert();
    const state = reactive({
      product: null,
      detail: null,
      provider: null,
      delivery: null,
      coupons: props.selecteCoupons,
      couponPrice: props.coupon_price,
      isShowBrandCoupons: false,
      isShowChangeOption: false,
      isShowChangeQty: false,
      isDeliveryValue: 0,
    });

    const loadProduct = async () => {
      const { data } = await ProductAPI.getProduct(props.cit_id);
      console.log("@@@@@@@@@@##############");
      console.log(data);
      console.log("@@@@@@@@@@##############");
      state.product = data.result_data;
      state.provider = data.provider;
      state.delivery = data.delivery;
      props.cde_id && loadDetail();
      state.isDeliveryValue = data.delivery.delivery_type_fee;

      context.emit("load:product", state);
      context.emit("den_id", state.product);
      context.emit("deliveryValue", data.delivery.delivery_type_fee);
      watch(
        () => props.isShippingAmount,
        (newValue) => {
          state.isDeliveryValue = newValue;
        }
      );
    };

    const loadDetail = async () => {
      const { data } = await ProductAPI.getProductDetail(props.cde_id);
      console.log("-> data", data);
      const detail = {
        ...data.result_data,

        // 상품 시중 가격
        cit_price: Number(
          state.product.cit_price ? state.product.cit_price : ""
        ),

        // 상품 할인된 가격
        cit_sale_price:
          Number(
            state.product.cit_sale_price ? state.product.cit_sale_price : ""
          ) +
          Number(
            data.result_data.order_price ? data.result_data.order_price : ""
          ),

        // 할인 금액
        discount_price:
          Number(state.product.cit_price ? state.product.cit_price : "") -
          Number(
            state.product.cit_sale_price ? state.product.cit_sale_price : ""
          ),

        // 상품명
        title: !data.result_data?.cde_title
          ? state.product?.cit_name
          : data.result_data?.cde_title,
        cit_name: state.product?.cit_name,
        order_price: Number(data.result_data?.order_price),

        qty: props.qty,
        is_sale: state.product?.is_sale,
        cit_subscribe_price: state.product?.cit_subscribe_price,
      };
      if (state.product.is_sale === "y") {
        detail.price =
          Number(state.product?.cit_sale_price) + Number(detail?.order_price);
      } else {
        detail.price =
          Number(state.product?.cit_price) + Number(detail?.order_price);
      }

      state.detail = detail;

      context.emit("load:detail", {
        ...state,
        cde_id: props?.cde_id,
        idx: props.idx,
      });
    };

    watch(
      () => props.qty,
      () => {
        loadProduct();
      }
    );

    props?.cit_id && loadProduct();

    return {
      ...toRefs(state),
      loadDetail,
      handleGetCupone() {
        let loginInfo = localStorage.getItem("mem_id");
        if (loginInfo) {
          state.isShowBrandCoupons = true;
        } else {
          showAlert("로그인 후 이용 하세요");
        }
      },
      redirectToBrand() {
        context.root.$router.push(`/brand/detail/${state.product.den_id}`);
      },
      handleSelected(e) {
        context.emit("input", e.target.checked, {
          ...state,
          cde_id: props.cde_id,
        });
      },

      handleClickChangeOption() {
        state.isShowChangeOption = true;
      },
      handleChangeOption(selected) {
        context.emit("change", selected, props.item);
        state.isShowChangeOption = false;
      },
      handelQtyChange() {
        context.root.$forceUpdate();
        context.emit("qtyChange", props.item);
        state.isShowChangeQty = false;
      },
      handleClickOrder() {
        // const selected = [
        //   {
        //     cit_id: props.cit_id,
        //     cde_id: props.cde_id,
        //     qty: props.qty,
        //   },
        // ];
        // const providers = {
        //   [state.provider.den_id]: { provider: state.provider },
        // };
        // state.selecteCoupons = props.selecteCoupons;
        // state.coupon_price = props.coupon_price;
        // state.selectedItem = selected;
        // state.selecteCoupons = state.selecteCoupons.filter((couponItem) => {
        //   if (couponItem.den_id === state.provider.den_id) {
        //     return couponItem;
        //   }
        // });
        context.emit("part", {
          ...state,
          cde_id: props.cde_id,
        });
        // context.root.$router.push({
        //   path: '/order',
        //    query: {
        //      selected: JSON.stringify(selected),
        //      coupons: JSON.stringify(state.selecteCoupons),
        //      couponPrice: state.coupon_price,
        //      providers: JSON.stringify(providers),
        //      selectedItem: JSON.stringify(state.selectedItem),
        //    },
        // });
      },
    };
  },
};
</script>

<style></style>
