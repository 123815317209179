<template>
  <div class="wrap">
    <back-title-basket :key="headerKey" :title="$t('Basket.BasketButton')" />
    <div v-if="items.length > 0" class="layout_body">
      <div class="inner p04 order_wrap">
        <div>
          <ul class="disflex cart_tab">
            <li>
              <input
                id="allChk"
                :checked="selectedItem.length === items.length ? true : false"
                type="checkbox"
                @change="handleClickCheckAll"
              />
              <label class="chkbox font2 color37" for="allChk">
                {{ $t("Basket.AllSelect") }} ( {{ selectedItem.length }}/{{
                  items.length
                }}
                )
              </label>
            </li>
            <li class="flex_right">
              <button
                class="btn_pop_open"
                type="button"
                @click="handleClickSelectedDelete"
              >
                {{ $t("Basket.ProductDelete") }}
              </button>
            </li>
            <li>
              <button
                class="sold_out_del"
                type="button"
                @click="handleClickSoldOutDelete"
              >
                {{ $t("Basket.AllDel") }}
              </button>
            </li>
          </ul>
        </div>
        <product-order-item
          v-for="(item, idx) in items"
          :key="`${item.cde_id + idx}`"
          ref="product"
          v-model="selected[idx]"
          :cde_id="item.cde_id"
          :cit_id="item.cit_id"
          :coupon_price="coupon_price"
          :idx="idx"
          :isSelect="true"
          :isShippingAmount="isShippingAmount[idx]"
          :isShowButton="true"
          :isShowClose="true"
          :isShowCoupon="true"
          :item="item"
          :qty="item.qty"
          :selecteCoupons="selecteCoupons"
          @change="handleChangeOption"
          @close="handleCloseProductItem"
          @input="handleSelectedItem"
          @part="handleItem"
          @qtyChange="handleQtyChangeEvent"
          @load:detail="handleLoadProductItem"
        />
        <!--        @load:detail="handleLoadProductItem"-->
        <div class="width100 all_price">
          <ul class="disflex cart_list">
            <li class="pt16">
              <span>{{ $t("Basket.TotalAmount") }}</span>
              <span :key="selectedItem.length">
                {{ $t("Basket.MonetaryUnit") }}
                {{ originalTotalPrice.numberFormat() }}
                <!--                {{ $t('Basket.MonetaryUnit') }} {{ total_price.numberFormat() }}-->
              </span>
            </li>
            <li>
              <span>{{ $t("Basket.DeliveryFee") }}</span
              ><span
                >{{ $t("Basket.MonetaryUnit") }}
                {{ total_delivery.numberFormat() }}</span
              >
            </li>
            <li>
              <span>{{ $t("Basket.DiscountAmount") }}</span
              ><span>
                {{ $t("Basket.MonetaryUnit") }}
                {{ discount_price.numberFormat() }}</span
              >
            </li>
            <li>
              <span>{{ $t("Basket.RankingSalePrice") }}</span>
              <span>
                {{ $t("Basket.MonetaryUnit") }}
                {{ ranking_Price > 0 ? `${ranking_Price.numberFormat()}` : 0 }}
              </span>
            </li>
            <li>
              <span>{{ $t("Basket.CouponDiscount") }}</span>
              <span>
                {{ $t("Basket.MonetaryUnit") }}
                {{ coupon_price > 0 ? `${coupon_price.numberFormat()}` : 0 }}
              </span>
            </li>
            <li style="justify-content: end">
              <a class="radi_btnB btn_pop_open" @click="handleClickApplyCoupon">
                {{ $t("Basket.CouponApply") }}
              </a>
            </li>
            <li class="all_payment">
              <span>{{ $t("Basket.PaymentAmount") }}</span>
              <strong class="font8 fontNum">
                {{ $t("Basket.MonetaryUnit") }}
                {{ pay_price.numberFormat() }}
              </strong>
            </li>
          </ul>
        </div>
      </div>
      <div class="w100_btn_box disflex cart_btn_foot">
        <div class="price_box font2">
          {{ $t("Basket.PaymentAmount") }}
          <strong class="font8 fontNum">
            {{ $t("Basket.MonetaryUnit") }}
            {{ pay_price.numberFormat() }}
          </strong>
        </div>
        <button
          class="black_btn font4"
          onclick="location.href='/sild/order/order.php'"
          style="display: none"
        >
          {{ $t("Basket.SelectOrder") }}
        </button>

        <button class="black_btn font4" @click="handleClickSubmit">
          {{ $t("Basket.Order") }}
        </button>
      </div>
    </div>
    <div v-else class="layout_body" style="padding-bottom: 160px">
      <p
        style="
          position: absolute;
          float: left;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        "
      >
        {{ $t("Basket.AlertNone") }}
      </p>
    </div>

    <sild-footer />
    <apply-discount
      v-if="isShowApplyDiscount"
      :brandsTotal="Object.keys(providers).length"
      :coupons="coupons"
      :providers="providers"
      :selecteCoupon="selecteCoupons"
      :selectedItems="selectedItem"
      :totalPrice="coupon_price"
      @close="isShowApplyDiscount = false"
      @confirm="handleConfirmCoupons"
    />
  </div>
</template>

<script>
import { reactive, ref, toRefs } from "vue-demi";
import SildFooter from "../../components/layouts/components/footers/SildFooter.vue";
import BackTitleBasket from "../../components/layouts/components/headers/BackTitleBasket.vue";
import ProductOrderItem from "../../components/ui/ProductOrderItem/ProductOrderItem.vue";
import useConfirm from "../../composables/dialogs/useConfirm";
import useToast from "../../composables/dialogs/useToast";
import useAlert from "../../composables/dialogs/useAlert";
import ApplyDiscount from "../../components/popups/ApplyDiscount.vue";
import PaymentAPI from "@/apis/PaymentAPI";

export default {
  components: {
    BackTitleBasket,
    SildFooter,
    ProductOrderItem,
    ApplyDiscount,
  },
  setup(_, context) {
    const { addMsg } = useToast();
    const { showAlert } = useAlert();
    const { showConfirm } = useConfirm();
    const product = ref(null);

    const state = reactive({
      items: [],
      coupons: [],
      selected: [],
      providers: [],
      selectedItem: [],
      itemInfos: [],
      payPrice: [],
      total_price: 0,
      deliveries: null,
      pay_price: 0,
      coupon_price: 0,
      total_delivery: 0,
      discount_price: 0,
      discountPrice: [],
      headerKey: Date.now(),
      isShowApplyDiscount: false,
      originalPayPrice: [],
      originalTotalPrice: 0,
      isChangeSelect: null,
      providersItems: [],
      isClick: false,
      rankingPrice: [],
      ranking_Price: 0,
      selecteCoupons: [],
      checkItems: [],
      setCheckItems: [],
      isShippingAmount: [],
    });

    let items = localStorage.getItem("basket"); // 1
    items = items ? JSON.parse(items) : [];
    state.selected = new Array(items.length).fill(true);
    state.items = items;

    const loadCartItems = async () => {
      state.isShippingAmount = [];
      let itemarray = [];
      let couponItems = [];
      let items = localStorage.getItem("basket");
      items = items ? JSON.parse(items) : [];
      state.items = items;
      state.selected = [...state.items];
      state.selectedItem = state.selected;
      state.selecteCoupons.map((couponItem) => {
        couponItems.push({
          ccl_id: couponItem.ccl_id,
        });
      });
      state.items.map((item) => {
        itemarray.push({
          cit_id: item.cit_id,
          cde_id: item.cde_id,
          qty: item.qty,
        });
      });
      console.log(state.items, "~~~~~~~~~~~~~~~");
      await loadCalculateData(itemarray, couponItems);
    };

    const loadCalculateData = async (item, couponItems) => {
      state.payPrice = [];
      state.originalPayPrice = [];
      state.discountPrice = [];
      // state.providers = [];
      state.rankingPrice = [];

      const { data } = await PaymentAPI.calculateData({
        items: JSON.stringify(item),
        mem_id: localStorage.getItem("mem_id")
          ? localStorage.getItem("mem_id")
          : null,
        coupons: JSON.stringify(couponItems),
      });
      data.goodsList.map((list_item) => {
        state.isShippingAmount.push(list_item.deliveryPrice);
      });
      console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
      console.log(state.isShippingAmount);
      console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
      let providers = {};
      state.providersItems.forEach((item) => {
        if (!providers[item.product.den_id]) {
          providers[item.product.den_id] = {
            den_id: item.product.den_id,
            total_price: 0,
            provider: item.provider,
            delivery: item.delivery,
          };
        }
        providers[item.product.den_id].total_price +=
          item.detail.price * item.detail.qty;
      });

      state.providers = providers;
      // 등급 할인
      state.total_delivery = data.sumDeliveryPrice;
      state.ranking_Price = data.sumGradeSale;
      // 쿠폰 할인
      state.coupon_price = data.sumCouponUse;
      // 할인 금액
      state.discount_price = data.sumDiscount;
      // 오리지널 상품합계금액
      state.originalTotalPrice = data.sumGoodsPrice;
      // 결제금액
      state.pay_price = data.settlePrice;
    };
    const deleteItems = (ids) => {
      let deleteItmes = [];
      showConfirm(context.root.$t("Basket.AlertDelete"), async () => {
        state.items.filter((item) => {
          ids.map((selecteItem) => {
            if (item.cde_id !== selecteItem) {
              deleteItmes.push(item);
            }
          });
        });
        //쿠폰리스트 업뎃
        state.providersItems = state.providersItems.filter(
          (item) => !ids.includes(item.cde_id)
        );
        localStorage.removeItem("basket");
        localStorage.setItem("basket", JSON.stringify(deleteItmes));
        addMsg(context.root.$t("Basket.AlertDel"));
        console.log("삭제");
        state.headerKey = Date.now();
        items = localStorage.getItem("basket");
        items = items ? JSON.parse(items) : [];
        state.selected = new Array(items.length).fill(true);
        state.items = items;
        await loadCartItems();
      });
    };

    items.length > 0 && loadCartItems();

    return {
      ...toRefs(state),
      product,
      _items: items,
      handleCloseProductItem(cde_id) {
        console.log("실행!!!!");
        const target = state.items.find((item) => item.cde_id === cde_id);
        deleteItems([target.cde_id]);
      },
      async handleQtyChangeEvent() {
        state.items = [];

        state.isClick = true;
        product.value.map((values) => {
          values.loadDetail();
        });
        let items = localStorage.getItem("basket");
        items = items ? JSON.parse(items) : [];
        state.selected = new Array(items.length).fill(true);
        state.items = items;
        await loadCartItems();
      },
      handleClickSelectedDelete() {
        if (state.selectedItem.length === 0) {
          showAlert(context.root.$t("Basket.AlertSelectDel"));
          return;
        }
        const ids = state.selectedItem.map((item) => item.cde_id);

        console.log(ids, state.items);

        showConfirm(context.root.$t("Basket.AlertDelete"), async () => {
          //상품리스트 로컬스토리지 삭제
          const delteItem = state.items.filter(
            (item) => !ids.includes(item.cde_id)
          );
          //쿠폰리스트 업뎃
          state.providersItems = state.providersItems.filter(
            (item) => !ids.includes(item.cde_id)
          );

          localStorage.removeItem("basket");
          localStorage.setItem("basket", JSON.stringify(delteItem));
          addMsg(context.root.$t("Basket.AlertDel"));
          state.headerKey = Date.now();
          await loadCartItems();
        });
      },

      handleClickSoldOutDelete() {
        const soldOutItems = state.itemInfos
          .filter((item) => item.detail.order_soldout === "y")
          .map((item) => item.cde_id);
        deleteItems(soldOutItems);
      },
      async handleSelectedItem(e, item) {
        let itemarray = [];
        let couponItems = [];
        if (e) {
          state.selectedItem.push(item);
          state.selectedItem = state.selectedItem.filter((selected) => {
            if (selected !== true) {
              return selected;
            }
          });
        } else {
          state.selectedItem = state.selectedItem.filter((selectItem) => {
            if (selectItem.cde_id !== item.cde_id && selectItem !== false) {
              return items;
            }
          });
        }

        state.selecteCoupons.map((couponItem) => {
          couponItems.push({
            ccl_id: couponItem.ccl_id,
          });
        });
        state.selectedItem.map((item) => {
          state.items.map((selected) => {
            if (selected.cde_id == item.cde_id) {
              itemarray.push({
                cit_id: selected.cit_id,
                cde_id: selected.cde_id,
                qty: selected.qty,
              });
            }
          });
        });
        await loadCalculateData(itemarray, couponItems);
      },
      async handleItem(part) {
        const items = [...state.items];
        state.items = items.filter((item) =>
          item.cde_id === part.cde_id ? item : ""
        );

        // const targetItems = Object.assign({}, state.providers);
        const targetNum = part.delivery.den_id;
        // targetItems.filter((item) => {
        //   item.den_id === targetNum;
        // });
        // state.providers = targetItems
        // state.providers = {targetNum:{targetItems}}
        var keys = Object.keys(state.providers);
        console.log(keys);

        const providers = keys
          .filter((num) => num === targetNum)
          .map((key) => state.providers[key]);
        state.providers = { [part.delivery.den_id]: providers[0] };

        context.root.$router.push({
          path: "/order",
          // query: {
          //   selected: JSON.stringify(selected),
          //   coupons: JSON.stringify(state.selecteCoupons),
          //   couponPrice: state.coupon_price,
          //   providers: JSON.stringify(state.providers),
          //   selectedItem: JSON.stringify(state.selectedItem),
          // },
        });

        localStorage.setItem("selected", JSON.stringify(state.items));
        localStorage.setItem("coupons", JSON.stringify(state.selecteCoupons));
        localStorage.setItem("couponPrice", state.coupon_price);
        localStorage.setItem("providers", JSON.stringify(state.providers));
        localStorage.setItem(
          "selectedItem",
          JSON.stringify(state.selectedItem)
        );
        let couponItems = [];
        state.selecteCoupons.map((couponItem) => {
          couponItems.push({
            ccl_id: couponItem.ccl_id,
          });
        });
        await loadCalculateData(state.items, couponItems);
      },
      handleClickCheckAll(e) {
        const checked = e.target.checked;
        state.selected = state.items.map(() => checked);
        state.selectedItem = checked ? [...state.itemInfos] : [];
        state.selectedItem = [...state.items];
        if (checked) {
          loadCartItems();
          state.selectedItem = [...state.items];
        } else {
          state.selectedItem = [];
        }

        loadCalculateData(state.selectedItem, state.coupons);
        console.log("@@@@@@@@@@@@");

        console.log("@@@@@@@@@@@@");
      },

      async handleChangeOption(selected, prev) {
        const items = [...state.items];
        const existItems =
          items.findIndex((item) => item.cde_id === selected[0].cde_id) > -1;
        if (existItems) {
          addMsg(context.root.$t("Basket.AlertPutIn"));
          return;
        }
        state.itemInfos = state.itemInfos.filter(
          (item) => item.cde_id !== prev.cde_id
        );
        console.log(state.itemInfos);
        state.items = items.map((item) =>
          item.cde_id === prev.cde_id ? selected[0] : item
        );
        state.selectedItem = state.selectedItem.map((item) => {
          const isChange = item.cde_id === prev.cde_id ? item : item;

          if (isChange) {
            const newItem = { ...item };
            newItem.detail = selected[0];
            return newItem;
          }
          return item;
        });
        localStorage.setItem("basket", JSON.stringify(state.items));
        window.location.reload();
      },
      handleLoadProductItem(infoItem) {
        console.log("-> infoItem", infoItem);
        state.providersItems.push(infoItem);
        let providers = {};

        state.providersItems.forEach((item) => {
          if (!providers[item.product.den_id]) {
            providers[item.product.den_id] = {
              den_id: item.product.den_id,
              total_price: 0,
              provider: item.provider,
              delivery: item.delivery,
            };
          }
          providers[item.product.den_id].total_price +=
            item.detail.price * item.detail.qty;
        });

        state.providers = providers;
      },
      handleConfirmCoupons(couponPrice, coupon) {
        console.log("-> couponPrice", couponPrice);
        console.log("-> coupon", coupon);
        state.selecteCoupons = [...coupon];
        state.selecteCoupons = coupon.reduce(function (acc, v) {
          if (acc.findIndex(({ ccl_id }) => ccl_id === v.ccl_id) === -1) {
            acc.push(v);
          }
          return acc;
        }, []);
        console.log("~~~~~~~~~~~~!!!!!!!!!!!!!!!!!!!!!!");
        console.log("-> couponPrice;", couponPrice);
        console.log("-> selecteCoupons;", state.selecteCoupons);
        console.log("~~~~~~~~~~~~!!!!!!!!!!!!!!!!!!!!!!");
        state.isShowApplyDiscount = false;
        loadCartItems();
      },
      handleClickApplyCoupon() {
        if (state.selectedItem.length === 0) {
          showAlert(context.root.$t("Basket.AlertApply"));
          return;
        }
        state.isShowApplyDiscount = true;
      },
      handleClickSubmit() {
        if (state.selectedItem.length === 0) {
          showAlert(context.root.$t("Basket.AlertOrder"));
          return;
        }
        let selectedArray = [];
        // state.selectedItem = [];
        // state.selectedItem = state.items;

        state.selectedItem.map((item) => {
          state.items.map((selected) => {
            if (selected.cde_id == item.cde_id) {
              selectedArray.push({
                cit_id: selected.cit_id,
                cde_id: selected.cde_id,
                qty: selected.qty,
              });
            }
          });
        });

        localStorage.setItem("selected", JSON.stringify(selectedArray));
        localStorage.setItem("coupons", JSON.stringify(state.selecteCoupons));
        localStorage.setItem("couponPrice", state.coupon_price);
        localStorage.setItem("providers", JSON.stringify(state.providers));
        localStorage.setItem(
          "selectedItem",
          JSON.stringify(state.selectedItem)
        );
        context.root.$router.push({
          path: "/order",
          // query: {
          //   selected: JSON.stringify(selected),
          //   coupons: JSON.stringify(state.selecteCoupons),
          //   couponPrice: state.coupon_price,
          //   providers: JSON.stringify(state.providers),
          //   selectedItem: JSON.stringify(state.selectedItem),
          // },
        });
      },
    };
  },
};
</script>

<style></style>
