<template>
  <form accept-charset="utf-8" method="post" name="payForm">
    <input :value="method" name="PayMethod" type="hidden" />
    <input :value="Moid" name="Moid" type="hidden" />
    <input :value="mid" name="MID" type="hidden" />
    <input :value="date" name="EdiDate" type="hidden" />
    <input :value="sign_data" name="SignData" type="hidden" />
    <input :value="buyDatas.Amt" name="Amt" type="hidden" />
    <input :value="buyDatas.title" name="GoodsName" type="hidden" />
    <input :value="info.mem_username" name="BuyerName" type="hidden" />
    <input :value="info.mem_phone" name="BuyerTel" type="hidden" />
    <input :value="info.mem_email" name="BuyerEmail" type="hidden" />
    <input :value="proseccEnv" name="ReturnURL" type="hidden" />
    <!--    value="https://www.sild.co.kr/order/complate/"-->
    <input name="GoodsCl" type="hidden" value="1" />
    <input name="TransType" type="hidden" value="0" />
    <input name="CharSet" type="hidden" value="utf-8" />
    <input name="ReqReserved" type="hidden" value="" />
    <input :value="device_type" name="device_type" type="hidden" />
    <input :value="info.mem_id" name="mem_id" type="hidden" />
    <input :value="info.mem_username" name="mem_username" type="hidden" />
    <input name="order_mem_type" type="hidden" value="guest" />
    <input name="IspCancelUrl" type="hidden" value="/order/order" />

    <template v-for="(val, key) in easyPayAttrs">
      <input :key="key" :name="key" :value="val" type="hidden" />
    </template>
  </form>
</template>

<script>
import useUserInfo from "../../composables/user/useUserInfo";
import PaymentAPI from "@/apis/PaymentAPI";
import { reactive, toRefs } from "vue-demi";
import { checkPlatform } from "@/utils/Buy";
import { nicepayStart } from "../../utils/Buy";
import useAlert from "../../composables/dialogs/useAlert";

export default {
  props: [
    "items",
    "method",
    "easyPayAttrs",
    "usePoint",
    "card_type",
    "coupons",
    "coupon_price",
    "total_price",
    "total_delivery",
    "delivery",
    "payType",
    "usegrade",
    "product_price",
    "isShippingAmount",
  ],
  setup(props, context) {
    const { info } = useUserInfo();
    const { showAlert } = useAlert();
    console.log(props.items);
    const state = reactive({
      mid: null,
      date: null,
      sign_data: null,
      Moid: null,
      proseccEnv: process.env.VUE_APP_NICEPAY_RETURL,
      cuponsData: [],
    });

    const use_point = Number(props.usePoint);
    const use_grade = Number(props.usegrade);
    const use_coupon = Number(props.coupon_price);
    const total_price = Number(props.total_price);
    const total_delivery = Number(props.total_delivery);
    const product_price = Number(props.product_price);
    console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");
    console.log("-> props.coupons", props.coupons);
    console.log(
      "use_gradeuse_gradeuse_gradeuse_gradeuse_gradeuse_gradeuse_grade",
      props.usegrade
    );
    console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");

    let buyDatas = {
      Amt: total_price,
      use_point,
      use_coupon,
      use_grade,
      total_price,
      total_delivery,
      product_price,
      title: props.items[0].product.cit_name,
      payment_type: props.card_type,
      items: JSON.stringify(props.items.map((item) => item.detail)),
      coupons: JSON.stringify(props.coupons),
      delivery: JSON.stringify(props.delivery),
      payment_way: props.payType.payment_way,
      payment_agr: props.payType.payment_agr,
    };
    props.coupons.map((coupon) => {
      state.cuponsData.push({
        ccl_id: coupon.ccl_id,
        price: props.coupon_price / props.coupons.length,
      });
    });

    const buyDatasObj = JSON.parse(buyDatas.items);
    props.isShippingAmount.map((item, idx) => {
      buyDatasObj[idx].delivery_price_value = item;
      return item;
    });
    buyDatas.items = JSON.parse(buyDatas.items).map((item, idx) => {
      return buyDatasObj[idx];
    });
    buyDatas.items = JSON.stringify(buyDatas.items);

    if (props.items.length > 1) {
      buyDatas.title += ` 외 ${props.items.length - 1}건`;
    }
    let deleteItems = [];
    let cattItems = localStorage.getItem("basket");
    let selectedItem = localStorage.getItem("selected");
    cattItems = cattItems ? JSON.parse(cattItems) : [];
    selectedItem = selectedItem ? JSON.parse(selectedItem) : [];
    cattItems.filter((item) => {
      selectedItem.map((selecteItem) => {
        console.log(selecteItem);
        if (item.cde_id !== selecteItem.cde_id) {
          deleteItems.push(item);
        }
      });
    });
    console.log("@@@@@@@@@@@@@@@@@@@");
    console.log(JSON.parse(buyDatas.items));
    console.log("@@@@@@@@@@@@@@@@@@@");

    PaymentAPI.getSignData({
      amt: buyDatas.Amt,
      delivery: buyDatas.delivery,
      items: buyDatas.items,
      title: buyDatas.title,
      total_delivery: buyDatas.total_delivery,
      total_price: buyDatas.total_price,
      use_coupon: buyDatas.use_coupon,
      coupons: JSON.stringify(state.cuponsData),
      use_point: buyDatas.use_point,
      use_grade: buyDatas.use_grade,
      card_type: buyDatas.card_type,
      mem_id: info?.mem_id,
      device_type: state.device_type,
      product_price: buyDatas.product_price,
    }).then(({ data }) => {
      state.mid = data.mid;
      state.date = data.date;
      state.Moid = data.order_number;
      state.sign_data = data.sign_data;
      context.root.$nextTick(() => {
        nicepayStart();
      });
    });

    //[PC 결제창 전용]결제 최종 요청시 실행됩니다. <<'nicepaySubmit()' 이름 수정 불가능>>
    window.nicepaySubmit = async function () {
      const form = new FormData(document.payForm);
      const params = { ...buyDatas, ...state };
      console.log(params);
      const basketItem = JSON.parse(localStorage.getItem("basket"));
      const propsItem = props.items;

      if (Array.isArray(basketItem) ?? basketItem.length > propsItem.length) {
        const newBasketItem = basketItem.filter((obj1) => {
          return !propsItem.some((obj2) => {
            return obj1.cde_id === obj2.cde_id;
          });
        });
        localStorage.removeItem("basket");
        localStorage.setItem("basket", JSON.stringify(newBasketItem));
        console.log("들어옴1");
      } else if (
        Array.isArray(basketItem) ??
        basketItem.length < propsItem.length
      ) {
        const newBasketItem = propsItem.filter((obj1) => {
          return !basketItem.some((obj2) => {
            return obj1.cde_id === obj2.cde_id;
          });
        });
        localStorage.removeItem("basket");
        localStorage.setItem("basket", JSON.stringify(newBasketItem));
        console.log("들어옴2");
      } else if (
        Array.isArray(basketItem) ??
        basketItem.length == propsItem.length
      ) {
        localStorage.removeItem("basket");
        console.log("들어옴3");
      }
      form.forEach((value, key) => {
        params[key] = value;
      });
      try {
        const { data } = await PaymentAPI.paymentRequest(params);
        if (data.status === "OK") {
          localStorage.removeItem("order_id");
          localStorage.setItem("order_id", data.order_id);
          // localStorage.setItem("basket", JSON.stringify(deleteItems));
          localStorage.removeItem("selected");
          localStorage.removeItem("coupons");
          localStorage.removeItem("couponPrice");
          localStorage.removeItem("providers");
          localStorage.removeItem("selectedItemData");
          location.href = `/order/complete/${data.order_id}/${data.status}`;
        } else if (data.status === "ERROR") {
          showAlert(
            context.root.$t("Order.AlertWait") + "(" + data.res_result + " )"
          );
        } else {
          throw context.root.$t("Order.AlertWait");
        }
      } catch (e) {
        showAlert(context.root.$t("Order.AlertWait"));
      }
    };

    //[PC 결제창 전용]결제창 종료 함수 <<'nicepayClose()' 이름 수정 불가능>>
    window.nicepayClose = function () {
      context.emit("close");
    };

    return {
      info,
      buyDatas,
      ...toRefs(state),
      device_type: checkPlatform(window.navigator.userAgent),
    };
  },
};
</script>

<style>
#bg_layer {
  display: none;
}
</style>
