<template>
  <down-full-up-popup
    :title="$t('Mypage.AddressList')"
    class="order-delivery-popup"
    @close="$emit('close')"
  >
    <delivery-list-view
      slot="body"
      ref="deliverfyListRef"
      type="popup"
      style="padding: 0"
    />
    <div slot="footer" class="order-delivery-popup-footer">
      <button
        type="button"
        class="black_btn font4 pos_fixed"
        @click="handleClickSet"
      >
        {{ $t('Delivery.DeliverySetting') }}
      </button>
    </div>
  </down-full-up-popup>
</template>

<script>
import { ref } from 'vue-demi';
import DownFullUpPopup from '../../../components/popups/DownFullUpPopup.vue';
import DeliveryListView from '../../mypages/delivery-list/DeliveryListView.vue';

export default {
  components: { DownFullUpPopup, DeliveryListView },
  setup(_, context) {
    const deliverfyListRef = ref();

    return {
      deliverfyListRef,
      handleClickSet() {
        // await deliverfyListRef.value.handleClickSetDefault();

        context.emit('set:default', deliverfyListRef.value.defaultItem);
      },
    };
  },
};
</script>

<style>
.order-delivery-popup .wrap > .layout_body {
  padding-top: 0;
  min-height: 88vh;
}

.order-delivery-popup .layout_contain .pop_body,
.order-delivery-popup .layout_contain .layout_box {
  padding-bottom: 0;
}
.order-delivery-popup-footer button {
  bottom: 0;
  width: 100% !important;
}
</style>
