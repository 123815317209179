<template>
  <down-full-up-popup
    :title="
      $t('Delivery.EditTitle', {
        TitleState: isChange ? $t('Ect.Edit') : $t('Main.Add'),
      })
    "
    style="z-index: 1000"
    class="edit-delivery-popup"
    @close="$emit('close')"
  >
    <template slot="body">
      <p class="pb4">
        <KsCheckBox
          v-model="params.is_default"
          :item="{ label: $t('Delivery.DefaultSetting') }"
        />
      </p>
      <div class="order_discount type2">
        <ul class="wrap_flex order_inform color37">
          <li>
            <label for="userName">{{ $t('Delivery.DeliveryTitle') }}</label>
            <input
              id="userName"
              v-model="params.mde_title"
              type="text"
              class="btn_group width100"
              :placeholder="$t('Delivery.AlertDeliveryAddress')"
            />
          </li>
          <li>
            <label for="userName">{{ $t('Delivery.Recipient') }}</label>
            <input
              id="userName"
              v-model="params.recipient_name"
              type="text"
              class="btn_group width100"
              :placeholder="$t('Delivery.AlertReceiver')"
            />
          </li>
          <li>
            <label for="phoneNum" class="font4">{{
              $t('Delivery.PhoneNumber')
            }}</label>
            <div class="phone_input disflex">
              <select-box
                v-model="params.phone[0]"
                :items="phoneNumbers"
                class="width33 type01"
              />
              <input-number
                v-model="params.phone[1]"
                :maxLength="4"
                class="btn_group width33"
              />
              <input-number
                v-model="params.phone[2]"
                :maxLength="4"
                class="btn_group width33"
              />
            </div>
          </li>
          <li>
            <label for="address" class="font4">{{
              $t('Delivery.ZipCode')
            }}</label>
            <input
              id="address"
              v-model="params.zipcode"
              type="text"
              class="btn_group back_color7"
              readonly
            />
            <button class="black_btn" @click="handleClickJuso">
              {{ $t('Delivery.ZipCode') }}
            </button>
          </li>
          <li>
            <label for="userName">{{ $t('Delivery.EditAddress') }}</label>
            <input
              id="userName"
              v-model="params.road_addr"
              type="text"
              class="btn_group width100 back_color7"
              readonly
            />
          </li>
          <li>
            <label for="userName">{{ $t('Delivery.DetailAddress') }}</label>
            <input
              id="userName"
              v-model="params.detail_addr"
              type="text"
              class="btn_group width100"
              :placeholder="$t('Delivery.AlertDetail')"
            />
          </li>
        </ul>
      </div>
    </template>
    <template slot="footer">
      <button
        type="button"
        class="black_btn gray_btn font4"
        @click="$emit('close')"
      >
        취소
      </button>
      <button type="submit" class="black_btn font4" @click="handleClickSubmit">
        {{ isChange ? $t('Ect.Edit') : $t('Main.Add') }}
      </button>
    </template>
  </down-full-up-popup>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import InputNumber from '../../../components/Inputs/InputNumber/InputNumber.vue';
import SelectBox from '../../../components/Inputs/SelectBox/SelectBox.vue';
import DownFullUpPopup from '../../../components/popups/DownFullUpPopup.vue';
import useUserInfo from '../../../composables/user/useUserInfo';
import useToast from '../../../composables/dialogs/useToast';
import DeliveryAPI from '../../../apis/DeliveryAPI';
import KsCheckBox from '../../../components/Inputs/KsCheckBox/KsCheckBox.vue';

export default {
  components: { DownFullUpPopup, InputNumber, SelectBox, KsCheckBox },
  props: ['item'],
  setup(props, context) {
    const { info } = useUserInfo();
    const { addMsg } = useToast();
    const state = reactive({
      params: {
        mem_id: info?.mem_id,
        mde_title: '',
        recipient_name: info.mem_username,
        recipient_phone: '',
        zipcode: '',
        road_addr: '',
        jibun_addr: '',
        detail_addr: '',
        is_default: true,
        phone: [],
      },
      isChange: props.item !== null,
    });

    if (info && !props.item) {
      const phone = info.mem_phone.replace(/[-]/g, '');
      state.params.phone = [
        phone.substr(0, 3),
        phone.substr(3, 4),
        phone.substr(7, 4),
      ];
    }

    if (props.item) {
      state.params = props.item;
      state.params.is_default = props.item.is_default === 'y';

      const phone = props.item.recipient_phone.replace(/[-]/g, '');
      state.params.phone = [
        phone.substr(0, 3),
        phone.substr(3, 4),
        phone.substr(7, 4),
      ];
    }

    const validate = () => {
      if (state.params.mde_title === '') {
        addMsg(context.root.$t('Delivery.AlertName'));
        return false;
      }

      const phone = state.params.phone.join('');

      if (!/^01([0|1|6|7|8|9])?([0-9]{3,4})?([0-9]{4})$/.test(phone)) {
        addMsg(context.root.$t('Delivery.AlertPhone'));
        return false;
      }

      if (!state.params.zipcode) {
        addMsg(context.root.$t('Delivery.AlertAddress'));
        return false;
      }

      if (!state.params.detail_addr) {
        addMsg(context.root.$t('Delivery.AlertDetail'));
        return false;
      }

      return true;
    };

    let isLoading = false;

    return {
      ...toRefs(state),
      phoneNumbers,
      handleClickJuso() {
        new window.daum.Postcode({
          oncomplete: function (data) {
            state.params.zipcode = data.zonecode;
            state.params.road_addr = data.roadAddress;
            state.params.jibun_addr = data.jibunAddress;
          },
        }).open();
      },
      async handleClickSubmit() {
        if (validate() && isLoading === false) {
          isLoading = true;

          const params = { ...state.params };
          params.recipient_phone = params.phone.join('');
          params.is_default = params.is_default ? 'y' : 'n';

          const { data } = await DeliveryAPI.registMyDelivery(params);

          isLoading = false;

          if (data.result) {
            if (data.mde_id) params.mde_id = data.mde_id;

            addMsg(
              context.root.$t('Delivery.AlertDetail', {
                DeliveryState: state.params.mde_id
                  ? context.root.$t('Ect.Edit')
                  : context.root.$t('Main.Add'),
              }),
            );

            console.log(params);

            context.emit('submit', params);
          }
        }
      },
    };
  },
};
const phoneNumbers = ['010', '011', '017', '019', '070'].map(val => ({
  key: val,
  label: val,
}));
</script>

<style>
.edit-delivery-popup .layout_popup {
  z-index: 1000;
  left: 0;
}
</style>
