<template>
  <div ref="addressPop" class="address_pop_wrap"></div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref, toRefs } from 'vue-demi';

export default {
  name: 'AddressDaumPOp',
  setup() {
    const { emit } = getCurrentInstance();
    const state = reactive({});
    const addressPop = ref(null);
    const GetAddressPOp = () => {
      new window.daum.Postcode({
        oncomplete: function (data) {
          console.log('data', data);
          emit('addressResult', data);
        },
        width: '100%',
        height: '400px',
      }).embed(addressPop.value);
    };
    onMounted(() => {
      GetAddressPOp();
    });
    return {
      ...toRefs(state),
      addressPop,
    };
  },
};
</script>

<style scoped>
.address_pop_wrap {
  width: 100%;
  height: 400px;
}
</style>
