<template>
  <down-full-up-popup
    v-if="providers"
    :title="$t('Basket.CouponApply')"
    class="apply-discount-popup"
    @close="$emit('close')"
  >
    <template slot="body">
      <div>
        <div class="info_tit point">
          <p class="font6">
            <strong>
              {{
                $t("Popup.TotalDiscount", {
                  Discount: total.numberFormat(),
                })
              }}
            </strong>
          </p>
          <span class="colora8 font2">
            {{ $t("Popup.PartCoupon") }}
          </span>
        </div>
      </div>

      <div class="inner p40">
        <h3 class="font4">
          {{ $t("Popup.BrandCoupon") }} <strong>{{ brandTotal }}</strong>
        </h3>
      </div>
      <template v-if="brands">
        <div v-for="key in keys" :key="key" class="coupon_layout">
          <brand-coupon-header
            :item="
              providers[key].provider ? providers[key].provider : providers
            "
          />
          <div class="noswiper_coupon coupon_swiper">
            <ul>
              <coupon-toggle-item
                v-for="item in brands[key]"
                :key="item.ccl_id"
                :disabled="
                  item.use_min === 'y' &&
                  providers[key].total_price * 1 < item.min_val
                "
                :isTotal="brandsTotal"
                :item="item"
                :value="
                  selecteCoupon
                    ? selecteCoupon.findIndex(
                        (coupon) => coupon.ccl_id === item.ccl_id
                      ) > -1
                    : false
                "
                @change="handleChangeCoupon"
              />
            </ul>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="no-coupons">{{ $t("Popup.NoneCoupon") }}</div>
      </template>
    </template>
    <template slot="footer">
      <button
        :class="`width100 ${total === 0 ? 'gray' : ''}`"
        type="button"
        @click="handleClickSubmit"
      >
        {{ $t("Popup.SelectConfirm") }}
      </button>
    </template>
  </down-full-up-popup>
</template>

<script>
import CouponToggleItem from "../ui/CouponToggleItem/CouponToggleItem.vue";
import DownFullUpPopup from "./DownFullUpPopup.vue";
import BrandCouponHeader from "../ui/BrandCouponHeader/BrandCouponHeader.vue";
import CouponAPI from "../../apis/CouponAPI";
import useUserInfo from "../../composables/user/useUserInfo";
import { reactive, toRefs } from "vue-demi";
import { getDiscountTotal } from "./actions/ApplyDiscountAction";
import PaymentAPI from "@/apis/PaymentAPI";
import useAlert from "@Composables/dialogs/useAlert";

export default {
  components: { DownFullUpPopup, CouponToggleItem, BrandCouponHeader },
  props: [
    "providers",
    "coupons",
    "selectedItems",
    "selecteCoupon",
    "totalPrice",
    "brandsTotal",
  ],
  setup(props, context) {
    const { showAlert } = useAlert();
    const $ = window.$;
    const { info } = useUserInfo();
    const keys = Object.keys(props.providers);
    console.log("-> keys", keys);
    console.log("-> props.providers", props.providers);
    console.log("-> props.selectedItems", props.selectedItems);

    const state = reactive({
      brands: null,
      total: 0,
      isSelect: false,
      selected: 0,
      brandTotal: 0,
      couponData: [],
      couponPrice: [],
      isArray: [],
      couponArray: [...props.selecteCoupon],
      isFirst: false,
      isDen: [],
      isError: false,
    });
    state.isDen = [];
    keys.forEach((den) => {
      state.isDen.push({ den_id: den, key: den });
    });

    if (props.selecteCoupon) {
      props.selecteCoupon.map((den_item, idx) => {
        if (den_item.den_id === state.isDen[idx].den_id) {
          state.isDen[idx].ccl_id = den_item.ccl_id;
        }
      });
    }

    console.log(state.isDen);

    const loadBrandCoupons = async () => {
      const { data } = await CouponAPI.getCouponsGroupByBrand({
        mem_id: info?.mem_id,
        den_ids: keys.join(","),
      });
      console.log("-> data", data);

      if (data.result) {
        console.log(data.result_data);
        let total = 0;

        Object.keys(data.result_data).forEach((key) => {
          total += data.result_data[key].length;
        });

        state.brandTotal = total;
        state.brands = data.result_data;
        state.selected = props.selecteCoupon ? props.selecteCoupon.length : 0;
        state.total = props.totalPrice ? props.totalPrice : 0;
        console.log("############################");
        console.log(state.total);
        console.log("############################");
      }
    };
    loadBrandCoupons();

    const LoadPirce = async () => {
      state.isArray = [];

      props.selectedItems.map((item) => {
        state.isArray.push({
          cit_id: item.cit_id,
          cde_id: item.cde_id,
          qty: item.qty,
        });
      });
      const { data } = await PaymentAPI.calculateData({
        mem_id: localStorage.getItem("mem_id"),
        items: JSON.stringify(state.isArray),
        coupons: JSON.stringify(
          state.couponArray.length > 0 ? state.couponArray : ""
        ),
      });
      console.log(data);
      if (data.errMsg === "") {
        state.total = data.sumCouponUse;
        console.log(state.total);
      } else {
        state.isError = true;
        showAlert("" + data.errMsg + "");
        state.couponArray = [];
        state.isArray = [];
        return;
      }
    };
    LoadPirce();

    return {
      keys,
      props,
      ...toRefs(state),
      getDiscountTotal,
      handleChangeCoupon(item, isCheck, target) {
        console.log(state.total);
        if (target.target.checked === false) {
          state.isDen.forEach((checkItem) => {
            if (checkItem.ccl_id === item.ccl_id) {
              checkItem.ccl_id = "";
            }
          });
          $(
            ".coupon_box.den_id_" + item.den_id + ".ccl_" + item.ccl_id + ""
          ).removeClass("selected");
          $(
            ".coupon_box.den_id_" +
              item.den_id +
              ".ccl_" +
              item.ccl_id +
              ' input[type="checkbox"]'
          ).attr("checked", false);
          target.target.checked = false;
        } else {
          state.isDen.forEach((isDen_item) => {
            if (isDen_item.den_id === item.den_id) {
              isDen_item.ccl_id = item.ccl_id;
            }
          });
          target.target.classList.add("selected");
          target.target.checked = true;
        }
        $(".coupon_box.den_id_" + item.den_id + "")
          .not(target.target)
          .removeClass("selected");
        $(".coupon_box.den_id_" + item.den_id + ' input[type="checkbox"]')
          .not(target.target)
          .attr("checked", false);

        state.couponArray = state.isDen;

        state.selectedItems = state.couponArray;
        LoadPirce();
      },
      handleClickSubmit() {
        // if (state.selected === 0) return;
        state.isFirst = false;
        $(".coupon_box").removeClass("on");
        if (state.isError) {
          context.emit("close", true);
          state.isError = false;
          return;
        } else {
          context.emit("confirm", state.total, state.couponArray);
        }
      },
    };
  },
};
</script>

<style>
.apply-discount-popup .brand-coupon-header {
  border-top: 1px solid #202020;
}
</style>
