<template>
  <down-full-up-popup
    :title="$t('Popup.CouponDown')"
    class="brand_coupon_popup"
    style="z-index: 1000"
    @close="$emit('close')"
  >
    <template slot="body">
      <div>
        <brand-coupon-header v-if="brand" :item="brand" />
        <!--.coupon_down(받기 전)/ 다운 받은 후sms .off 클래스 추가하면 됨-->
        <div class="noswiper_coupon coupon_swiper">
          <ul v-if="coupons && coupons.length > 0">
            <coupon-item
              v-for="item in coupons"
              :key="item.ccp_id"
              :isOn="item.download_count == 0"
              :item="item"
              @download="item.download_count++"
            />
          </ul>
          <div v-else class="no-coupons">{{ $t('Popup.NoneCoupon') }}</div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <button type="button" @click="handleClickSubmit">
        {{ $t('Popup.Complete') }}
      </button>
    </template>
  </down-full-up-popup>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import BrandAPI from '../../apis/BrandAPI';
import CouponAPI from '../../apis/CouponAPI';

import useUserInfo from '../../composables/user/useUserInfo';
import CouponItem from '../ui/CouponItem/CouponItem.vue';
import BrandCouponHeader from '../ui/BrandCouponHeader/BrandCouponHeader.vue';
import DownFullUpPopup from '@Popups/DownFullUpPopup';

export default {
  props: ['den_id'],
  components: { CouponItem, BrandCouponHeader, DownFullUpPopup },
  setup(props, context) {
    const { info } = useUserInfo();

    const state = reactive({
      coupons: null,
      brand: null,
    });

    const loadBrandInfo = async () => {
      const { data } = await BrandAPI.getBrand({
        mem_id: info?.mem_id,
        den_id: props.den_id,
      });

      state.brand = data.result_data;
    };

    const loadBrandCoupons = async () => {
      const { data } = await CouponAPI.getBrandCoupons({
        mem_id: info?.mem_id,
        den_id: props.den_id,
      });

      state.coupons = data.result_data;
    };

    loadBrandInfo();
    loadBrandCoupons();

    return {
      ...toRefs(state),

      handleClickSubmit() {
        context.emit('close');
        // context.emit('confirm', state.coupons); 기존 소스 아무것도 보내고 있었음
      },
    };
  },
};
</script>

<style>
.no-coupons {
  border-bottom: 1px solid #d9d9d9;
  font-size: 12px;
  padding: 6px 0 20px 0;
  text-align: center;
}
.brand_coupon_popup .pop_foot button {
  width: 100%;
}
.order_wrap .search_brand_con .brand_coupon_popup .pop_header {
  border-bottom: 1px solid #202020;
}
.brand_coupon_popup .order_coupon .search_brand_con {
  margin-bottom: 10px;
  padding: 0;
}
</style>
