<template>
  <ul class="fist_floor">
    <li>
      <p>{{ title }}</p>
      <button
        v-if="showCloseButton"
        type="button"
        class="close"
        @click="$emit('close')"
      />
    </li>
    <li class="product_pri">
      <div>
        <template v-if="detail.order_soldout !== 'y'">
          <number-counter @input="handleInputCount" />
        </template>
      </div>
      <div>
        <template v-if="product.is_sale === 'y'">
          <em class="discount_price">
            ₩ {{ `${detail.cit_price}`.numberFormat() }}
          </em>
          <p class="fontNum">
            ₩
            {{ detail.price.numberFormat() }}
          </p>
        </template>
        <template v-else>
          <p class="fontNum">₩ {{ `${detail.cit_price}`.numberFormat() }}</p>
        </template>
      </div>
    </li>
  </ul>
</template>

<script>
import NumberCounter from '../../Inputs/NumberCounter/NumberCounter.vue';
export default {
  props: ['product', 'detail', 'value', 'showCloseButton'],
  components: { NumberCounter },

  setup({ detail }, context) {
    let title = detail.cde_title.replace(/[,]/g, ' / ');

    if (detail.order_soldout === 'y') {
      title += ' (품절)';
    }

    return {
      title,
      handleInputCount(value) {
        context.emit('input', value);
      },
    };
  },
};
</script>

<style></style>
