<template>
  <div v-if="is_options" :key="optionKey">
    <template v-for="(option, idx) in options">
      <template v-if="idx === 0">
        <select-box
          :key="option.cio_id"
          v-model="optionValues[idx]"
          :items="option.options"
          :noItemText="`${option.option_name} 선택`"
          :placeholder="`${option.option_name} 선택`"
          class="type01"
          name="test1"
          @input="handleChangeOption(idx)"
        />
      </template>
      <template v-else>
        <select-box
          :key="`${option.cio_id}-${optionValues[idx - 1]}`"
          v-model="optionValues[idx]"
          :items="optionValues[idx - 1] ? option.options : []"
          :noItemText="`${option.option_name} 선택`"
          :placeholder="`${option.option_name} 선택`"
          class="type01"
          name="test2"
          style="margin-top: -1px"
          @input="handleChangeOption(idx)"
        />
      </template>
    </template>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import SelectBox from '../../Inputs/SelectBox/SelectBox.vue';
import ProductAPI from '../../../apis/ProductAPI';

export default {
  props: ['product'],
  components: { SelectBox },
  setup(props, context) {
    const { product } = props;
    console.log('@@@', product);
    const state = reactive({
      selected: [],
      details: null,
      options: null,
      is_options: false,
      optionValues: [],
      optionKey: Date.now(),
    });

    const loadOptions = async () => {
      const { data } = await ProductAPI.getProductOptions(product.cit_id);
      // console.log("데이타",data)
      // console.log("옵션선택 데이터",data.options.length)
      state.is_options = data.options.length >= 1; // 옵션이 1개라도 나오게끔
      console.log(state.is_options);
      // 옵션을 사용하지 않는 경우
      if (!state.is_options) {
        const detail = createDetailItem(data['details'][0]);

        detail['is_options'] = false;

        context.emit('select', { ...detail });

        return;
      }

      // 옵션을 사용하는 경우
      setDetails(data['details']);
      setOptions(data['options']);
    };

    loadOptions();

    const createDetailItem = detail => {
      console.log(Number(product.cit_price), Number(detail.order_price));
      const newDetail = {
        ...detail,

        qty: 1,

        is_last_depth: true,

        // 상품 시중 가격
        cit_price: Number(product.cit_price) + Number(detail.order_price),

        // 상품 할인된 가격
        cit_sale_price: product.cit_sale_price,

        // 할인 금액
        discount_price: product.cit_price - product.cit_sale_price,

        // 상품명
        cde_title: !detail.cde_title ? product.cit_name : detail.cde_title,
      };

      if (product.is_sale === 'y') {
        newDetail.price =
          Number(product.cit_sale_price) + Number(detail.order_price);
      } else {
        newDetail.price =
          Number(product.cit_price) + Number(detail.order_price);
      }

      return newDetail;
    };

    const details = {};

    const setDetails = items => {
      items.forEach(detail => {
        const newDetail = createDetailItem(detail);
        const keys = detail.cde_title.split(',').map(key => key.trim());

        lookup(keys, 0, details, newDetail);
      });
    };

    const lookup = (arr, idx, obj, detail) => {
      const key = arr[idx];
      if (arr.length - 1 === idx) {
        obj[key] = detail;
        return;
      }

      if (!obj[key]) obj[key] = {};

      lookup(arr, idx + 1, obj[key], detail);
    };

    const setOptions = items => {
      state.options = items.map(option => {
        console.log('-> option', option);
        const newOption = { ...option, options: [] };
        newOption.options = newOption.option_val
          .split(',')
          .map(value => ({ key: value.trim(), label: value.trim() }));

        return newOption;
      });
    };

    const setNewOptions = (target, idx) => {
      const keys = Object.keys(target);
      const isLastNextOption = target[keys[0]].is_last_depth;

      if (isLastNextOption) {
        const options = [...state.options];

        options[idx + 1].options = options[idx + 1].options.map(value => {
          const option = { ...value };
          const item = target[option.key];
          const overPrice = Number(item.order_price);

          option.label = [
            option.key,
            overPrice > 0 ? ` (+₩ ${overPrice.numberFormat()})` : '',
            item.order_soldout === 'y' ? '[품절]' : '',
          ].join('');

          option.disabled = item.order_soldout === 'y';

          console.log(option);

          return option;
        });
      }
    };

    return {
      ...toRefs(state),
      handleChangeOption(idx) {
        const values = [];

        let isLast = false;
        let target = details;

        for (let i = 0; i <= idx; i++) {
          const key = state.optionValues[i];

          values.push(key);
          console.log(details, target, key);
          isLast = target[key].is_last_depth;

          target = target[key];
        }

        if (isLast) {
          context.emit('select', { ...target, count: 1, is_options: true });

          state.optionValues = [];
          state.optionKey = Date.now();
        } else {
          setNewOptions(target, idx);
          state.optionValues = values;
        }
      },
    };
  },
};
</script>

<style></style>
