export const getDiscountTotal = (providers, coupons) => {
  let total = 0;

  coupons.forEach(coupon => {
    const price = providers[coupon.den_id].total_price;
    console.log('-> price', price);

    if (coupon.use_min === 'y' && coupon.min_val > price) {
      return;
    }
    console.log('-> coupon.price_type', coupon.price_type);

    switch (coupon.price_type) {
      case '1':
        {
          const discount = price / coupon.ccp_val;
          console.log('-> price', price);
          console.log('-> coupon.ccp_val', coupon.ccp_val);
          console.log('-> discount', discount);

          if (coupon.use_max === 'y') {
            total += discount < coupon.max_val ? discount : coupon.max_val;
          } else {
            total += discount;
          }
        }
        break;
      case '2':
        total += Number(coupon.ccp_val);
        break;
      default:
        break;
    }
  });

  return total;
};
