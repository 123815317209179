import { axios } from '@/plugins/axios';

export default {
  getMyDeliveryList(params) {
    return axios.post('/delivery/getMyDeliveryList', params);
  },

  getMyDefaultDelivery(params) {
    return axios.post('/delivery/getMyDefaultDelivery', params);
  },

  registMyDelivery(params) {
    return axios.post('/delivery/registMyDelivery', params);
  },

  deleteDelivery(params) {
    return axios.post('/delivery/deleteDelivery', params);
  },

  setDefaultDelivery(params) {
    return axios.post('/delivery/setDefaultDelivery', params);
  },
};
