<template>
  <div>
    <div class="wrap">
      <back-title-basket :title="$t('Mypage.AddressList')" />
      <!-- body : START -->
      <div class="layout_body">
        <div class="inner p04">
          <p class="pa24 font4 fontW500" v-html="$t('Delivery.Subject')"></p>
          <p class="userinfo btw_flex pb14">
            <button class="white_btn" @click="handleClickSelected">
              {{ $t('Ect.SelectDel') }}
            </button>
            <button
              class="black_btn ico_plus btn_pop_open"
              @click="handleClickCreate"
            >
              {{ $t('Delivery.DeliveryAdd') }}
            </button>
          </p>
          <div class="table_type">
            <table>
              <colgroup>
                <col style="width: 25%" />
                <col style="width: 25%" />
                <col style="width: 50%" />
              </colgroup>
              <thead>
                <tr>
                  <th>{{ $t('Brand.TagAll') }}</th>
                  <th>{{ $t('Delivery.DeliveryTitle') }}</th>
                  <th>배송지 정보</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="list.length > 0">
                  <tr v-for="item in list" :key="item.mde_id">
                    <td>
                      <div class="input_chk_box basic_adr">
                        <input
                          :checked="
                            defaultItem && item.mde_id === defaultItem.mde_id
                          "
                          name="addr_list"
                          type="radio"
                          @change="defaultItem = item"
                        />
                        <label class="color37 font2" for="default"></label>
                      </div>
                    </td>
                    <td>{{ item.mde_title }}</td>
                    <td>
                      <div>
                        <ul>
                          <li>
                            {{ $t('Delivery.Recipient') }} :
                            {{ item.recipient_name }}
                          </li>
                          <li>
                            {{ $t('Delivery.PhoneNum') }} :
                            {{ item.recipient_phone }}
                          </li>
                          <li>
                            {{ $t('Delivery.address') }} : {{ item.road_addr }}
                            {{ item.detail_addr }}
                          </li>
                        </ul>
                        <div class="btm_foot">
                          <button
                            class="btn_pop_open font2"
                            type="button"
                            @click="handleClickUpdate(item)"
                          >
                            {{ $t('Ect.Edit') }}
                          </button>
                          <button
                            class="font2"
                            type="button"
                            @click="handleClickDelete(item)"
                          >
                            {{ $t('Basket.delete') }}
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
                <tr v-else>
                  <th colspan="3">{{ $t('Delivery.NoneDelivery') }}</th>
                </tr>
              </tbody>
            </table>
          </div>
          <button
            v-if="type === 'view'"
            class="black_btn font4 pos_fixed"
            type="button"
            @click="handleClickSetDefault"
          >
            {{ $t('Delivery.DeliverySetting') }}
          </button>
        </div>
      </div>
      <sild-footer v-if="type === 'view'" />
    </div>
    <edit-delivery-popup
      v-if="isShowEdit"
      :item="updateItem"
      @close="isShowEdit = false"
      @submit="handleEditDelivery"
    />
  </div>
</template>

<script>
import BackTitleBasket from '../../../components/layouts/components/headers/BackTitleBasket.vue';
import { onMounted, reactive, toRefs } from 'vue-demi';
import DeliveryAPI from '../../../apis/DeliveryAPI';
import SildFooter from '../../../components/layouts/components/footers/SildFooter.vue';
import useUserInfo from '../../../composables/user/useUserInfo';
import useConfirm from '../../../composables/dialogs/useConfirm';
import useToast from '../../../composables/dialogs/useToast';
import EditDeliveryPopup from './EditDeliveryPopup.vue';

export default {
  components: { SildFooter, EditDeliveryPopup, BackTitleBasket },
  props: {
    type: { default: 'view' },
  },
  setup(_, context) {
    const { info } = useUserInfo();
    const { addMsg } = useToast();
    const { showConfirm } = useConfirm();

    const state = reactive({
      list: [],
      defaultItem: null,
      isShowEdit: false,
      updateItem: null,
    });

    const loadDatas = async () => {
      const { data } = await DeliveryAPI.getMyDeliveryList({
        mem_id: info?.mem_id,
      });
      console.log('--------------------------------------');
      console.log(data);
      console.log('--------------------------------------');

      data.result_data.forEach((item) => {
        if (item.is_default === 'y') {
          state.defaultItem = item;
        }
      });
      if (state.defaultItem === null && data.result_data.length > 0) {
        state.defaultItem = data.result_data[0];
        await DeliveryAPI.setDefaultDelivery({
          mem_id: info?.mem_id,
          mde_id: state.defaultItem.mde_id,
        });
      }

      state.list = data.result_data;
    };

    const deleteDelivery = async (mde_id) => {
      await DeliveryAPI.deleteDelivery({
        mde_id,
      });

      state.list = state.list.filter((item) => item.mde_id !== mde_id);
      state.defaultItem = state.list[0];

      addMsg(context.root.$t('Delivery.DeliveryDel'));
      await DeliveryAPI.setDefaultDelivery({
        mem_id: info?.mem_id,
        mde_id: state.defaultItem.mde_id,
      });
    };
    onMounted(() => {
      loadDatas();
    });

    return {
      ...toRefs(state),
      handleClickDelete(item) {
        showConfirm(context.root.$t('Basket.AlertDelete'), () => {
          deleteDelivery(item.mde_id);
        });
      },
      handleClickSelected() {
        if (state.list.length === 0 || !state.defaultItem) {
          addMsg(context.root.$t('Delivery.DeliverySelect'));
        }

        this.handleClickDelete(state.defaultItem);
      },

      handleClickCreate() {
        state.isShowEdit = true;
        state.updateItem = null;
      },

      handleEditDelivery() {
        loadDatas();

        state.updateItem = null;
        state.isShowEdit = false;
      },

      handleClickUpdate(item) {
        state.updateItem = item;
        state.isShowEdit = true;
      },

      async handleClickSetDefault() {
        await DeliveryAPI.setDefaultDelivery({
          mem_id: info?.mem_id,
          mde_id: state.defaultItem.mde_id,
        });

        addMsg(context.root.$t('Delivery.DefaultDelivery'));
      },
    };
  },
};
</script>

<style>
tbody th {
  background: #fff;
}
</style>
