<template>
  <li
    :class="`coupon_box type2 disflex ${value ? 'selected' : ''} den_id_${
      item.den_id
    } ccl_${item.ccl_id} ${value ? 'on' : ''}`"
  >
    <label class="type2 disflex" style="width: 100%">
      <input
        :checked="value"
        :value="item.ccl_id"
        type="checkbox"
        @change="handleChange"
      />
      <div class="coupon_content disflex" style="width: 90%">
        <h4>{{ item.ccp_name }}</h4>
        <div style="width: 100%">
          <div class="center-content">
            {{ item.den_name }}
            <div v-if="item.coupon_type !== 'delivery'">
              {{
                item.price_type === '1'
                  ? `${item.ccp_val}%`
                  : `₩ ${item.ccp_val.numberFormat()} `
              }}
              {{ $t('Button.DiscountCoupon') }}
            </div>
            <div v-else>무료배송쿠폰</div>
          </div>
          <div class="start_date_con">
            <p>발급일 : {{ down_dtm }}</p>
          </div>
          <!--          <p class="center-content">{{ item.ccp_desc }}</p>-->

          <p
            class="limited_amount"
            v-if="item.min_val !== '0' && item.coupon_type !== 'delivery'"
          >
            최소구매금액 : ₩
            {{ item.min_val === '0' ? '0' : item.min_val.numberFormat() }}<br />
          </p>
          <p
            class="limited_amount"
            v-if="item.max_val !== '0' && item.coupon_type !== 'delivery'"
          >
            ₩
            {{ item.max_val === '0' ? '0' : item.max_val.numberFormat() }} 까지
            할인<br />
          </p>
          <div class="validate_coupon">
            유효기간 {{ item.use_start_date }} ~ {{ item.use_end_date }}
          </div>
        </div>
      </div>
    </label>
  </li>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import moment from 'moment';

export default {
  props: ['item', 'disabled', 'value', 'isTotal'],
  setup(props, context) {
    const state = reactive({
      isTotalCoupon: props.isTotal,
      isClick: null,
      down_dtm: moment(props.item.down_dtm).format('YYYY-MM-DD'),
    });
    console.log('@@@@@@@@@@@@@@@');
    console.log(props.value);
    console.log(props.item);
    console.log('--------------------');
    console.log(state.down_dtm);
    console.log('--------------------');
    console.log('@@@@@@@@@@@@@@@');
    return {
      ...toRefs(state),
      handleChange(e) {
        if (props.disabled) {
          e.target.checked = false;
          return;
        }
        state.isClick = e.target.classList.contains('on');
        context.emit(
          'change',
          props.item,
          state.isClick,
          e,
          state.isTotalCoupon
        );

        // for (let i = 0; i < e.path.length; i++) {
        //   const path = e.path[i];
        //
        //   if (path.className.indexOf('coupon_box') > -1) {
        //     const classes = path.className.split(' ');
        //
        //     // const isPush = ArrayUtils.toggle(classes, 'selected');
        //
        //     path.className = classes.join(' ');
        //
        //     break;
        //   }
        // }
      },
    };
  },
};
</script>

<style scoped>
.coupon_box .coupon_content {
  justify-content: space-around;
  width: 100%;
}
.coupon_box.type2 {
  max-height: initial;
  overflow: hidden;
}
.coupon_box.selected {
  border: 1px solid #373737;
}

.coupon_box h4,
.coupon_box .center-content {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.coupon_content p {
  color: #a8a8a8;
  font-size: 12px;
}
.validate_coupon {
  color: #a8a8a8;
  font-size: 12px;
}
.limited_amount {
  color: #a8a8a8;
  font-size: 12px;
}
</style>
