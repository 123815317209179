<template>
  <div class="cart_procdcut disflex">
    <div class="cart_left">
      <image-bg-src
        :src="product.cit_file_1"
        class="conp_img"
        @click="redirectToProduct"
      />
    </div>
    <div class="cart_right">
      <ul v-if="detail" class="disflex cart_list">
        <li class="cart_top" @click="redirectToProduct">
          {{ detail.cit_name }}
        </li>
        <li>
          <span>{{ $t("MyStyle.Option") }}</span>
          <span>{{ detail.title.replace(",", " / ") }}</span>
        </li>
        <li>
          <span>{{ $t("Product.Quantity") }}</span
          ><span> {{ $t("Button.Number", { Num: detail.qty }) }}</span>
        </li>
        <li>
          <span>{{ $t("Order.ProductAmount") }}</span>
          <span>
            ₩
            {{
              (
                (detail.cit_price + detail.order_price) *
                detail.qty
              ).numberFormat()
            }}</span
          >
        </li>
        <li>
          <span>{{ $t("Basket.DiscountAmount") }}</span>
          <span class="colora8">
            ₩
            {{
              (product.is_sale === "y"
                ? detail.discount_price * detail.qty
                : 0
              ).numberFormat()
            }}
          </span>
        </li>
        <li>
          <span>배송비</span>
          <span>
            ₩
            {{ Number(deliveryValue).numberFormat() }}
          </span>
        </li>
        <li class="cart_bottom">
          <strong>{{ $t("Product.DiscountApply") }}</strong>
          <strong>
            ₩
            {{ (detail.price * detail.qty).numberFormat() }}
          </strong>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ImageBgSrc from "../ImageBlock/ImageBgSrc.vue";
import { watch } from "vue-demi";

export default {
  components: { ImageBgSrc },
  props: ["product", "detail", "deliveryValue"],
  setup(props, context) {
    watch(
      () => props.isSetCopons,
      (newValue) => {
        console.log("-> newValue", newValue);
      }
    );
    return {
      redirectToProduct() {
        context.root.$router.push(`/product/${props.product.cit_id}`);
      },
    };
  },
};
</script>

<style></style>
