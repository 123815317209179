<template>
  <div v-if="info" class="wrap">
    <back-title-basket :title="$t('Order.OrderComplete')" />

    <div class="layout_body box_p60">
      <div class="inner order_wrap order_layout">
        <div class="order_msg">
          <p class="flex_right" v-html="$t('Order.CompleteText')"></p>
        </div>
        <div class="order_num">
          <p class="font2">{{ $t('OrderInquiry.OrderNumber') }}</p>
          <strong>{{ info.order_id }}</strong>
        </div>
        <div>
          <h3 class="new_sub_title font4">{{ $t('Order.OrderProduct') }}</h3>
        </div>

        <product-order-item
          v-for="(item, idx) in detail"
          :key="item.cde_id"
          :cde_id="item.cde_id"
          :cit_id="item.cit_id"
          :isSelect="false"
          :isShippingAmount="isShippingAmount[idx].delivery_price_value"
          :item="item"
          :qty="item.qty"
        />
      </div>

      <!--결제금액-->
      <div class="border_top order_discount order_bill">
        <div class="order_tit">
          <h6>{{ $t('Basket.PaymentAmount') }}</h6>
        </div>
        <div class="inner order_discount">
          <div class="width100">
            <ul class="disflex cart_list pay_list">
              <li>
                <span>{{ $t('Order.AllProductAmount') }}</span>
                <span>
                  ₩
                  {{ info.total_cit_sale_price.numberFormat() }}
                </span>
              </li>
              <li>
                <span>{{ $t('Order.AllShippingAmount') }}</span>
                <span>₩ {{ info.delivery_price.numberFormat() }}</span>
              </li>
              <li>
                <span> {{ $t('Order.UseCoupon') }}</span>
                <span>₩ {{ info.use_coupon.numberFormat() }}</span>
              </li>
              <li>
                <span>{{ $t('Order.UsePoint') }}</span>
                <span>₩ {{ info.use_point.numberFormat() }}</span>
              </li>
              <li>
                <span>{{ $t('Basket.RankingSalePrice') }}</span>
                <span>₩ {{ info.use_grade.numberFormat() }}</span>
              </li>
              <li class="all_payment">
                <span class="font6">{{ $t('Order.AllPaymentAmount') }}</span>
                <strong class="font8">
                  ₩ {{ pay_price.numberFormat() }}
                </strong>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--결제정보-->
      <div v-if="info.payMethod === 'VBANK'" class="border_top order_discount">
        <div class="order_tit">
          <h6>{{ $t('Order.DepositInfo') }}</h6>
        </div>
        <div class="inner p04 address_info">
          <div class="width100">
            <li class="table_wrap ver2">
              <div>
                <table class="table_box">
                  <colgroup>
                    <col style="width: 27%" />
                    <col style="width: 73%" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>{{ $t('Order.DepositBank') }}</th>
                      <td>{{ info.vbank_name }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('Order.AccountNumber') }}</th>
                      <td>{{ info.vbank_num }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('Order.AccountName') }}</th>
                      <td>{{ info.mem_username }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('Order.DepositAmount') }}</th>
                      <td>₩ {{ pay_price.numberFormat() }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>
          </div>
        </div>
      </div>
      <!--결제정보-->
      <div class="border_top order_discount">
        <div class="order_tit">
          <h6>{{ $t('OrderInquiry.PaymentInfo') }}</h6>
        </div>
        <div class="inner p04 address_info">
          <div class="width100">
            <li class="table_wrap ver2">
              <div>
                <table class="table_box">
                  <colgroup>
                    <col style="width: 27%" />
                    <col style="width: 73%" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>{{ $t('OrderInquiry.OrderNumber') }}</th>
                      <td>{{ info.order_id }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('OrderInquiry.PaymentType') }}</th>
                      <td>{{ getPayMethodName(info.payMethod) }}</td>
                    </tr>
                    <template v-if="info.payMethod === 'CARD'">
                      <tr>
                        <th>{{ $t('Order.ApprovalNumber') }}</th>
                        <!--                        <td>{{ info.auth_code }}</td>-->
                        <td>{{ info.auth_code }}</td>
                      </tr>
                      <tr>
                        <th>{{ $t('OrderInquiry.PaymentDate') }}</th>
                        <td>{{ info.applDtm }}</td>
                      </tr>
                    </template>
                    <tr>
                      <th>{{ $t('Basket.DeliveryFee') }}</th>
                      <td>₩ {{ info.delivery_price.numberFormat() }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('Order.AllPaymentAmount') }}</th>
                      <td>₩ {{ pay_price.numberFormat() }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>
          </div>
        </div>
      </div>
      <!--배송지정보-->
      <div class="border_top order_discount pb20">
        <div class="order_tit">
          <h6>{{ $t('Delivery.DeliveryInfo') }}</h6>
        </div>
        <div class="inner p04 address_info">
          <div class="width100 table_wrap">
            <div>
              <table class="table_box">
                <colgroup>
                  <col style="width: 27%" />
                  <col style="width: 73%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>{{ $t('Delivery.Recipient') }}</th>
                    <td>{{ info.recipient_name }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t('Delivery.PhoneNum') }}</th>
                    <td>{{ phoneNumberFormat(info.recipient_phone) }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t('Delivery.address') }}</th>
                    <td>
                      {{ info.recipient_zip }}
                      {{ info.recipient_addr1 }}
                      {{ info.recipient_addr2 }}
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t('OrderInquiry.ShippingMemo') }}</th>
                    <td>{{ info.recipient_memo }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="btw_btn">
          <router-link
            class="white_btn font2"
            to="/mypage/order-delivery-search"
          >
            {{ $t('Order.RouterOrder') }}
          </router-link>
          <router-link class="black_btn font2" to="/">
            {{ $t('Order.Main') }}
          </router-link>
        </div>
      </div>
    </div>

    <sild-footer />
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import SildFooter from '../../components/layouts/components/footers/SildFooter.vue';
import BackTitleBasket from '../../components/layouts/components/headers/BackTitleBasket.vue';
import OrderAPI from '@/apis/OrderAPI';
import ProductOrderItem from '../../components/ui/ProductOrderItem/ProductOrderItem.vue';
import useUserInfo from '../../composables/user/useUserInfo';
import moment from 'moment';

export default {
  components: { BackTitleBasket, SildFooter, ProductOrderItem },
  setup(_, context) {
    const { info } = useUserInfo();

    const state = reactive({
      info: null,
      detail: null,
      pay_price: 0,
      totalDate: null,
      isShippingAmount: [],
      total_cit_price: 0,
    });

    const loadOrderInfo = async () => {
      // if (!localStorage.getItem("order_id")) {
      //   router.push("/");
      // }
      const { data } = await OrderAPI.getOrderComplateInfo({
        mem_id: info?.mem_id,
        order_id: localStorage.getItem('order_id')
          ? localStorage.getItem('order_id')
          : '',
      });
      state.isShippingAmount = data.result_data.detail;
      console.log('##################');
      console.log(data.result_data);
      console.log(state.isShippingAmount);
      console.log('##################');

      window.localStorage.removeItem('order_id');

      console.log('결제 정보 : ', data);
      state.info = data.result_data;
      state.detail = data.result_data.detail;
      console.log('###############');
      console.log(state.info);

      console.log('###############');

      state.total_cit_price = state.detail.reduce((prev, cur) => {
        return Number(prev) + Number(cur.cit_price);
      }, 0);
      console.log(state.total_cit_price);
      let applDtm = moment(
        `20${state.info.applDate} ${state.info.applTime}`
      ).format('YYYY-MM-DD HH:mm:ss');

      state.info.applDtm = applDtm;
      state.pay_price = Number(state.info.total_price); // + Number(state.info.delivery_price);
      // - Number(state.info.use_point);
      // - Number(state.info.use_coupon)
    };

    loadOrderInfo();

    return {
      user: info,
      ...toRefs(state),
      getPayMethodName(type) {
        switch (type) {
          case 'KAKAO':
            return context.root.$t('OrderInquiry.KakaoPay');
          case 'NAVER':
            return context.root.$t('OrderInquiry.NaverPay');
          case 'CARD':
            return context.root.$t('OrderInquiry.Card');
          case 'VBANK':
            return context.root.$t('OrderInquiry.VirtualAccount');
          case 'BANK':
            return context.root.$t('OrderInquiry.BankTransfer');
          default:
            return '';
        }
      },

      phoneNumberFormat(phone) {
        return [
          phone.substr(0, 3),
          phone.substr(3, 4),
          phone.substr(7, 4),
        ].join('-');
      },
    };
  },
};
</script>

<style></style>
