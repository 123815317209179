<template>
  <down-up-popup
    :title="$t('Popup.OptionChange')"
    class="fix_foot_btn option_change_popup"
    @close="$emit('close')"
  >
    <div slot="body" class="option_box">
      <div v-if="product" class="review_con disflex">
        <product-image :item="product" class="review_pro" />
        <div class="detail_pro">
          <h6 class="fontEn font2">{{ product.cit_name }}</h6>
          <p class="font2">
            {{ product.cit_summary }}
          </p>
        </div>
      </div>

      <product-option-group :product="product" @select="handleSelectOption" />
      <div class="option_value" style="display: block">
        <product-option-item
          v-for="item in selected"
          :key="item.cde_id"
          v-model="item.qty"
          :detail="item"
          :product="product"
          :showCloseButton="item.is_options"
          @close="handleDeleteItem(item)"
        />
      </div>
      <ul v-if="selected.length > 0" class="sec_floor">
        <li>
          <P> {{ $t('Order.AllProductAmount') }} </P>
          <h6 class="fontNum">
            ₩
            {{
              selected
                .reduce((acc, item) => acc + item.price * item.qty, 0)
                .numberFormat()
            }}
          </h6>
        </li>
      </ul>
    </div>
    <div slot="footer" class="pop_foot">
      <button type="button" @click="$emit('close')">
        {{ $t('Popup.Cancel') }}
      </button>
      <button type="button" @click="$emit('change', selected)">
        {{ $t('Popup.Change') }}
      </button>
    </div>
  </down-up-popup>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import ProductAPI from '../../apis/ProductAPI';
import ProductOptionItem from '../ui/ProductOptionList/ProductOptionItem.vue';
import DownUpPopup from './DownUpPopup.vue';
import ProductOptionGroup from '../ui/ProductOptionList/ProductOptionGroup.vue';
import ProductImage from '../ui/ProductImage/ProductImage.vue';

export default {
  components: {
    ProductOptionItem,
    DownUpPopup,
    ProductOptionGroup,
    ProductImage,
  },
  props: ['product'],
  setup(props, context) {
    const state = reactive({
      is_options: false,
      selected: [],
      optionKey: Date.now(),
    });

    const loadOptions = async () => {
      const { data } = await ProductAPI.getProductOptions(props.product.cit_id);

      state.is_options = data.options.length > 0;
    };

    loadOptions();

    return {
      ...toRefs(state),
      handleSelectOption(item) {
        state.selected = [item];
        context.emit('input', state.selected);
      },
      handleDeleteItem(item) {
        const selected = state.selected.filter(value => value !== item);
        state.selected = selected;
        context.emit('input', selected);
      },
    };
  },
};
</script>

<style scoped>
.sec_floor li {
  display: flex;
  justify-content: space-between;
}
.review_con.disflex {
  padding: 0;
}
</style>
